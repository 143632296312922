import React from "react";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollTop";
import ContactForm from "../components/ContactForm";
import SEO from "../components/SEO";
import WhatsAppMsg from "../components/WhatsApp";
const Connect = () => {
  return (
    <>
      <SEO
        title="Connect with Revolution Education & Collaboration | Coltec Global"
        description="Get in touch with Coltec Global, pioneers in interactive flat panels and education technology. Transform classrooms and workplaces together."
        keywords="Coltec Contact, Interactive Flat Panel Solutions, Education Technology Support, Business Collaboration Tools, Smart Classroom Displays, Touch Screen Panels, Digital Whiteboards, Contact Coltec, Smart Board Technology, Interactive Displays for Businesses, Education Collaboration Tools, Corporate Meeting Technology"
        ogTitle="Contact Coltec Global | Revolutionizing Interactive Learning"
        ogDescription="Reach out to Coltec Global for cutting-edge interactive flat panels and education technology solutions. Empower your classrooms and businesses with the future of collaboration."
        ogImage="https://content.qeeb.in/coltec/connect-banner-img.webp"
        ogUrl="https://coltec-global.com/connect"
        canonicalUrl="https://coltec-global.com/connect"
        ogImageAlt="Modern Coltec reception area with sleek wooden panel design and a welcoming atmosphere for customer engagement and inquiries."
        ogSiteName="Coltec Global"
        ogType="website"
      />
      <div className="bg-gray-900">
        <Header />
        <Banner
          imgSrc="https://content.qeeb.in/coltec/connect-banner-img.webp"
          altText="Modern Coltec reception area with sleek wooden panel design and a welcoming atmosphere for customer engagement and inquiries."
          heading="Connect with COLTEC Global"
          bannerHeight="md:h-dvh h-[50vh]"
          opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-neutral-liner z-0"
          paragraphText="Connect with COLTEC experts and get the best solution tailored to your unique requirements."
          positionClasses="absolute md:bottom-64 bottom-20 left-0 lg:left-32"
          headClasses="max-w-2xl text-3xl font-semibold tracking-tight leading-[1.7] text-white sm:text-4xl drop-shadow-lg mt-3"
          paragraphTextClasses="text-base text-white max-w-lg mt-4"
        />
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-20 py-10 max-w-7xl mx-auto">
          <h3 className="md:text-[40px] text-[28px] font-normal lg:text-center md:mb-8 mb-5">
            Locations
          </h3>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
            <div className="rounded-lg bg-gray-50 p-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Design Office
              </h3>
              <address className="mt-3 space-y-2 text-sm not-italic leading-6 text-gray-600">
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="https://content.qeeb.in/coltec/aboutus/location-icon.svg"
                    className="h-4 mr-2"
                  />
                  1014 - 155 Hillcrest Avenue, Mississauga, Canada ON L5B 3Z2
                </p>
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="https://content.qeeb.in/coltec/aboutus/call-icon.svg"
                    className="h-4 mr-1.5"
                  />
                  +1 (647) 564-6779
                </p>
              </address>
            </div>
            <div className="rounded-lg bg-gray-50 p-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Manufacturing and Regional Office
              </h3>
              <address className="mt-3 space-y-2 text-sm not-italic leading-6 text-gray-600">
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="https://content.qeeb.in/coltec/aboutus/location-icon.svg"
                    className="h-4 mr-2"
                  />
                  Survey No.75/A Gagan Pahad, Rajendra Nagar Municipality Ranga
                  Reddy District, Telangana.
                </p>
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="https://content.qeeb.in/coltec/aboutus/call-icon.svg"
                    className="h-3.5 mr-1.5"
                  />
                  +91 1800 309 8822
                </p>
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="https://content.qeeb.in/coltec/aboutus/mail-icon.svg"
                    className="h-3.5 mr-2"
                  />
                  sales@coltec-global.com
                </p>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#F1F1F1]">
        <div className="px-6 lg:px-8 lg:pt-20 py-10 max-w-7xl mx-auto">
          <div className="lg:text-center mb-10">
            <h3 className="md:text-[40px] text-[28px] font-normal mb-2">
              Contact Sales
            </h3>
            <p className="text-sm max-w-xl mx-auto">
              If you experience any technical challenges or require assistance
              following your purchase, feel free to connect with our technical
              support
            </p>
          </div>
          <ContactForm />
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
      <WhatsAppMsg />
    </>
  );
};

export default Connect;
