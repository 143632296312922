import React from "react";
import Header from "../components/Header";
import SEO from "../components/SEO";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollTop";
import WhatsAppMsg from "../components/WhatsApp";

const RefundReturnPolicy = () => {
  return (
    <>
      <div className="about-us-page">
        <SEO
          title="Refund Return Policy | Coltec Global"
          description="Learn how Coltec Global collects, uses, and protects your personal data in compliance with GDPR."
          keywords="Refund Return Policy, GDPR Compliance, Data Protection, Coltec Global"
          ogTitle="Refund Return Policy | Coltec Global"
          ogDescription="Understand Coltec Global's approach to data privacy and protection."
          ogUrl="https://coltec-global.com/refund-return-policy"
          ogType="website"
        />
        <Header />
        <div className="max-w-7xl px-5 lg:px-8 m-auto mx-auto mt-24 pb-12">
          <main className="p-10 bg-white border-pink-200 shadow-lg border rounded-2xl">
            <div className="text-gray-800">
              <h1 className="text-xl font-bold text-center mb-6">
                COLTEC Products – Refund & Return Policy
              </h1>
              <p className="mb-2 text-base">
                <span className="font-medium">Effective Date:</span> January 1,
                2025
              </p>

              {/* Section 1 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  1. Scope of This Policy
                </h2>
                <ul className="list-disc list-inside space-y-2 text-[15px]">
                  <li>
                    <span className="font-medium">Direct Purchases:</span> This Refund &amp; Return
                    Policy applies to all COLTEC products purchased directly
                    through our official website or authorized direct sales
                    channels of COLTEC Global.
                  </li>
                  <li>
                    <span className="font-medium">Purchases via Resellers:</span> If you purchased
                    your COLTEC product from an authorized reseller, their own
                    refund and return policies apply. Please contact the
                    reseller directly for assistance or to initiate any return,
                    exchange, or refund process.
                  </li>
                </ul>
              </section>

              {/* Section 2 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  2. Eligibility for Returns &amp; Refunds (Direct Purchases)
                </h2>
                <ul className="list-disc list-inside space-y-2 text-[15px]">
                  <li>
                    <span className="font-medium">Time Frame:</span> You may request a return or
                    refund within 30 days of receiving your COLTEC product. If
                    more than 30 days have passed since delivery, a full refund
                    is typically not available.
                  </li>
                  <li>
                    <span className="font-medium">Proof of Purchase:</span> A valid order number or
                    sales receipt is required for refunds or exchanges.
                  </li>
                  <li>
                    <span className="font-medium">Condition of the Product:</span> Returned products
                    must be in their original condition, including all
                    accessories, packaging, and documentation, unless the item
                    is defective.
                  </li>
                </ul>
              </section>

              {/* Section 3 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  3. Non-Returnable Items
                </h2>
                <ul className="list-disc list-inside space-y-2 text-[15px]">
                  <li>
                    <span className="font-medium">Customized Products:</span> Any product that has
                    been specially configured or customized is not eligible for
                    return.
                  </li>
                  <li>
                    <span className="font-medium">Software Licenses:</span> If a software license
                    key has been activated, it may not be refundable unless
                    required by law.
                  </li>
                  <li>
                    <span className="font-medium">Damaged or Misused Products:</span> Items showing
                    damage, misuse, or alteration outside our warranty coverage
                    may not qualify for a refund or exchange.
                  </li>
                </ul>
              </section>

              {/* Section 4 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  4. Process for Returns (Direct Purchases)
                </h2>
                <ol className="list-decimal list-inside space-y-4">
                  <li>
                    <span className="font-medium">Contact Customer Support</span>
                    <ul className="list-disc list-inside ml-5 mt-2 space-y-1">
                      <li>
                        Email:{" "}
                        <a
                          href="mailto:support@coltec-global.com"
                          className="text-blue-600 hover:underline"
                        >
                          support@coltec-global.com
                        </a>
                      </li>
                      <li>
                        Toll-Free:{" "}
                        <a
                          href="tel:18003098822"
                          className="text-blue-600 hover:underline"
                        >
                          1800 309 8822
                        </a>
                      </li>
                      <li>Provide your order details and reason for return.</li>
                    </ul>
                  </li>
                  <li>
                    <span className="font-medium">Obtain a Return Authorization (RA)</span>
                    <ul className="list-disc list-inside ml-5 mt-2 space-y-1">
                      <li>
                        A customer support representative will assess your
                        request and, if approved, issue a Return Authorization
                        (RA) number.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="font-medium">Packaging &amp; Shipping</span>
                    <ul className="list-disc list-inside ml-5 mt-2 space-y-1">
                      <li>
                        Repack the product securely with all accessories and
                        original packaging.
                      </li>
                      <li>
                        Clearly mark the RA number on the outside of the
                        package.
                      </li>
                      <li>
                        You are responsible for shipping costs unless the return
                        is due to a defective product or an error on our part.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span className="font-medium">Inspection</span>
                    <ul className="list-disc list-inside ml-5 mt-2 space-y-1">
                      <li>
                        Once we receive the returned item, our quality control
                        team will inspect it to ensure it meets eligibility
                        criteria.
                      </li>
                    </ul>
                  </li>
                </ol>
              </section>

              {/* Section 5 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  5. Refund Processing
                </h2>
                <ul className="list-disc list-inside space-y-2 text-[15px]">
                  <li>
                    <span className="font-medium">Approval &amp; Timeline:</span> If the product is
                    approved for a return, we will process your refund within 30
                    days of approval.
                  </li>
                  <li>
                    <span className="font-medium">Refund Method:</span> Refunds will be issued to
                    the original payment method. It may take additional time to
                    appear on your statement, depending on your bank or card
                    issuer.
                  </li>
                  <li>
                    <span className="font-medium">Restocking Fee:</span> For non-defective items
                    returned for reasons unrelated to product performance, a
                    restocking fee of 10–20% may be deducted from the refund.
                    The exact percentage depends on the product type, condition,
                    and any applicable handling fees.
                  </li>
                </ul>
              </section>

              {/* Section 6 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  6. Exchanges &amp; Replacements
                </h2>
                <ul className="list-disc list-inside space-y-2 text-[15px]">
                  <li>
                    <span className="font-medium">Defective or Damaged Items:</span> If your product
                    arrives defective or damaged, contact us within 7 days of
                    delivery for a replacement at no cost (or a full refund if a
                    replacement is unavailable).
                  </li>
                  <li>
                    <span className="font-medium">Upgrades/Model Exchanges:</span> You may request
                    to exchange for a different model. Any price difference must
                    be settled before the new product is shipped.
                  </li>
                </ul>
              </section>

              {/* Section 7 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  7. Warranty Coverage
                </h2>
                <p className="text-[15px]">
                  Any refund or exchange does not affect your statutory rights
                  or any applicable warranties. For warranty details, please
                  refer to the warranty documents received at the time of
                  purchase, or contact our customer support.
                </p>
              </section>

              {/* Section 8 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  8. Cancellations
                </h2>
                <p className="text-[15px]">
                  If you need to cancel your order before it ships, contact us
                  immediately at{" "}
                  <a
                    href="tel:18003098822"
                    className="text-blue-600 hover:underline"
                  >
                    1800 309 8822
                  </a>{" "}
                  or{" "}
                  <a
                    href="mailto:support@coltec-global.com"
                    className="text-blue-600 hover:underline"
                  >
                    support@coltec-global.com
                  </a>
                  . If the order has already shipped, follow the standard return
                  process described above.
                </p>
              </section>

              {/* Section 9 */}
              <section className="mb-8">
                <h2 className="text-base font-semibold mb-4">
                  9. Changes to This Policy
                </h2>
                <p className="text-[15px]">
                  We reserve the right to modify or update this Refund &amp;
                  Return Policy at any time. Any changes will take effect
                  immediately upon posting on our website.
                </p>
              </section>

              {/* Section 10 */}
              <section>
                <h2 className="text-base font-semibold mb-4">10. Contact Us</h2>
                <p className="text-[15px]">
                  For questions or assistance regarding returns and refunds,
                  please reach out to us at:
                </p>
                <ul className="list-disc list-inside space-y-1 mt-2 text-[15px]">
                  <li>
                    Email:{" "}
                    <a
                      href="mailto:support@coltec-global.com"
                      className="text-blue-600 hover:underline"
                    >
                      support@coltec-global.com
                    </a>
                  </li>
                  <li>
                    Toll-Free:{" "}
                    <a
                      href="tel:18003098822"
                      className="text-blue-600 hover:underline"
                    >
                      1800 309 8822
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </main>
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default RefundReturnPolicy;
