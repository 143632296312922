import React, { useState, useEffect } from "react";
import AdminNavbar from "../components/AdminNavbar";
import AdminUserDetails from "../components/AdminUserDetails";
import CommentDetails from "../components/CommentDetails";
import CreateDiscountCoupon from "../components/CreateDiscountCoupon";
import GetCoupons from "../components/GetCoupons";

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem("activeTab") || "users";
  });
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Call this function after a coupon is created successfully
  const handleRefreshCoupons = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  // Update localStorage whenever activeTab changes
  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  return (
    <div className="overflow-hidden">
      <AdminNavbar />
      <div className="pt-8">
        <div className="flex flex-wrap space-x-4 px-8">
          <button
            onClick={() => setActiveTab("users")}
            className={`${
              activeTab === "users"
                ? "bg-blue-600 text-white border border-gray-500"
                : "bg-white text-black"
            } main-class px-8 py-2.5 rounded-full text-sm`}
          >
            Users
          </button>
          {/* New Comments tab button */}
          <button
            onClick={() => setActiveTab("comments")}
            className={`${
              activeTab === "comments"
                ? "bg-blue-600 text-white"
                : "bg-white text-black"
            } main-class px-8 py-2.5 rounded-full text-sm`}
          >
            Comments
          </button>
          <button
            onClick={() => setActiveTab("coupons")}
            className={`${
              activeTab === "coupons"
                ? "bg-blue-600 text-white"
                : "bg-white text-black"
            } main-class px-8 py-2.5 rounded-full text-sm`}
          >
            Coupons
          </button>
        </div>
        <main className="pt-8">
          {activeTab === "users" && <AdminUserDetails />}
          {activeTab === "comments" && <CommentDetails />}
          {activeTab === "coupons" && (
            <div className="grid grid-cols-12 gap-4 pl-8">
              {/* Left column: Create coupon */}
              <div className="col-span-3">
                <CreateDiscountCoupon refreshCoupons={handleRefreshCoupons} />
              </div>

              {/* Right column: View coupons */}
              <div className="col-span-9">
                <GetCoupons refreshTrigger={refreshTrigger} />
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default AdminPage;
