import React from "react";

const Thanks = () => {
  return (
    <>
      <div className="flex justify-center items-center h-screen overflow-hidden">
        <div className="bg-white p-10 rounded-lg shadow-md text-center border border-gray-200">
          <div className="flex justify-center items-center bg-[#f3f8ff] rounded-full h-32 w-32 mx-auto">
            <i className="text-[#667cbc] text-[55px] leading-[100px]">✓</i>
          </div>
          <h1 className="text-[#517fff] font-semibold text-[28px] my-4">
            Form submitted successfully
          </h1>
          <p className="text-[#404F5E] font-nunito text-[17px]">
            Thanks for contacting us,
            we'll be in touch shortly!
          </p>
        </div>
      </div>
    </>
  );
};

export default Thanks;
