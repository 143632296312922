import React, { useState, useEffect } from "react";
import { testimonials } from "../utils/testimonials";

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [cardsToShow, setCardsToShow] = useState(3);

  // Update cardsToShow based on window width
  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth < 640) {
        setCardsToShow(1);
      } else if (window.innerWidth < 1100) {
        setCardsToShow(2);
      } else {
        setCardsToShow(3);
      }
    };

    updateCardsToShow();
    window.addEventListener("resize", updateCardsToShow);
    return () => window.removeEventListener("resize", updateCardsToShow);
  }, []);

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex(
          (prevIndex) =>
            (prevIndex + 1) % Math.ceil(testimonials.length / cardsToShow)
        );
        setFade(false);
      }, 500); // Duration to match your fade animation
    }, 6000); // Change every 6 seconds

    return () => clearInterval(interval);
  }, [cardsToShow]);

  // Handler to update current index when a circle dot is clicked
  const handleDotClick = (index) => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setFade(false);
    }, 500);
  };

  // Calculate which testimonials to display
  const startIndex = currentIndex * cardsToShow;
  const currentTestimonials = testimonials.slice(
    startIndex,
    startIndex + cardsToShow
  );

  // Helper to determine card width classes
  const getCardWidthClass = () => {
    if (cardsToShow === 1) return "w-full";
    if (cardsToShow === 2) return "w-1/2";
    return "xl:w-1/3 md:w-1/2";
  };

  // Generate the dots based on the number of slides
  const totalSlides = Math.ceil(testimonials.length / cardsToShow);

  return (
    <div className="relative px-6 lg:px-8 lg:py-12 pb-12 max-w-7xl m-auto">
      <h2 className="md:text-3xl text-2xl font-semibold md:text-center mb-14">
        What <span className="text-[#79adf3]">Teachers</span> have to say About
        Us
      </h2>

      {/* Navigation Icons */}
      <div className="relative">
        {/* Testimonial Carousel */}
        <div
          className={`testimonial-container ${
            fade ? "fade-out" : "fade-in"
          } transition-opacity`}
        >
          <div className="flex xl:space-x-16 space-x-10 justify-center">
            {currentTestimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`relative lg:h-[195px] bg-gradient-to-b from-[#fafbff] to-[#dcf3ff] 
                rounded-tl-[18.45px] rounded-tr-[18.45px] rounded-bl-[18.45px] shadow-[0px_3.0756008625030518px_18.45360565185547px_0px_rgba(152,172,198,0.40)]
                p-5 rounded-lg ${getCardWidthClass()}`}
              >
                <div className="absolute top-[-25px]">
                  <img src={testimonial.qoute_img} alt="quotes" />
                </div>
                <div>
                  <h3 className="text-black col-span-full text-sm font-semibold mb-3">
                    {testimonial.title}
                  </h3>
                  <p className="w-[260px] text-black text-[10px] font-normal leading-[13px]">
                    {testimonial.testimonial}
                  </p>
                </div>
                <div className="flex justify-end items-center absolute xl:right-[-40px] lg:right-[-20px] right-0 bottom-[-50px]  md:bottom-2">
                  <div className="h-[110px] w-[110px]">
                    <img
                      className="h-[110px] w-[110px]"
                      src={testimonial.profile_img}
                      alt={testimonial.name}
                    />
                  </div>
                  <div
                    className="absolute right-14 bottom-0 w-[220px] p-2 h-[55px] bg-gradient-to-b from-[#fafbff] to-[#dcf3ff] 
                  rounded-tl-[18.45px] rounded-tr-[18.45px] rounded-bl-[18.45px] shadow-[0px_3.0756008625030518px_18.45360565185547px_0px_rgba(152,172,198,0.40)]"
                  >
                    <h4 className="text-black text-xs font-medium leading-[14.76px] mb-[1px]">
                      {testimonial.name}
                    </h4>
                    <p className="text-black text-[8.5px] font-medium mb-[1px]">
                      {testimonial.role}
                    </p>
                    <p className="text-black text-[8.5px]">
                      {testimonial.institution}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Circle dots for navigation */}
        <div className="absolute lg:bottom-[-50px] bottom-[-75px] left-1/2 transform -translate-x-1/2 flex space-x-2">
          {Array.from({ length: totalSlides }).map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-2 h-2 rounded-full cursor-pointer ${
                currentIndex === index ? "bg-blue-500" : "bg-gray-300"
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
