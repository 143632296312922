export const cardData = [
    {
      id: 1,
      title: "Paper",
      text: "Touch technology",
      description: "Write or sketch notes as naturally as on real paper—ideal for brainstorming ideas, annotating slides, or illustrating concepts on the fly. It’s a hands-on feature that keeps students actively engaged on your smart interactive flat panel for teaching.",
      icon: "https://content.qeeb.in/coltec/landing/paper-touch.svg",
      border:"h-[180px] absolute top-0 right-[-30px] px-5"
    },
    {
      id: 2,
      title: "Toughened",
      text: "Glass Screen",
      description: "Built to withstand the hustle and bustle of a busy classroom, this smart whiteboard features a durable surface. The interactive touch screen ensures seamless lessons, handling eager hands, accidental bumps, and continuous use—keeping learning smooth and uninterrupted.",
      icon: "https://content.qeeb.in/coltec/landing/glass-screen.svg",
      border:"h-[180px] absolute top-0 right-[-26px] px-5"
    },
    {
      id: 3,
      title: "4K UHD",
      text: "Display",
      description: "Bring geography maps, science diagrams, and historical images to vivid life with ultra-clear visuals. Crisp details help students grasp complex topics effortlessly, experiencing visuals just like a TV. Make the most of your smart Interactive TV for an engaging and immersive teaching experience.",
      icon: "https://content.qeeb.in/coltec/landing/uhd-4k.svg",
      border:"h-[180px] absolute top-0 right-[-30px] px-5"
    },
    {
      id: 4,
      title: "Dual",
      text: "Stereo speakers",
      description: "Enhance every lesson with crystal-clear sound—perfect for educational videos, interactive language drills, or virtual field trips that fully immerse your class. This smart whiteboard setup ensures no one misses a word.",
      icon: "https://content.qeeb.in/coltec/landing/stereo-speakers.svg",
      border:"h-[180px] absolute top-0 right-[-35px] px-5"
    },
    {
      id: 5,
      title: "Android 14",
      text: "Operating System",
      description: "Access the latest teaching apps, tools, and interactive content with ease. Quick updates and a user-friendly interface let you adapt your smart interactive Flat Panel for teaching on the spot, keeping learning engaging and fresh.",
      icon: "https://content.qeeb.in/coltec/landing/andriod-14.svg",
      border:"h-[180px] absolute top-0 right-[-35px] px-5"
    },
    {
      id: 6,
      title: "Octa-Core",
      text: "Processor",
      description: "Seamlessly switch between videos, quizzes, and presentation slides without lag. This powerful processor ensures smooth lessons, so you can focus on teaching instead of waiting for the smart whiteboard to catch up.",
      icon: "https://content.qeeb.in/coltec/landing/processor.svg",
      border:"h-[180px] absolute top-0 right-[-35px] px-5"
    },
  ];
  