import React, { useState, useEffect, useMemo, useRef } from "react";
import ScrollToTopButton from "../../components/ScrollTop";
import SEO from "../../components/SEO";
import WhatsAppMsg from "../../components/WhatsApp";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import { Link } from "react-scroll";
import { startImageRotation } from "../../utils/imageUtils";
import { cardData } from "../../utils/benefitsCardData";
import { featureData } from "../../utils/featureData";
import { features } from "../../utils/featureDetails";
import FAQ from "../../components/FAQ";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { faqData } from "../../utils/landingFAQ";
import { clientImages } from "../../utils/clientImages";
import { slides } from "../../utils/featureSlides";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
// import required modules
import { Autoplay, Navigation, Scrollbar } from "swiper/modules";
import TestimonialCarousel from "../../components/TestimonialCarousel";
import SafetyComfort from "../../components/SafetyComfort";
import Landingform from "../../components/LandingForm";

const InteractiveFlatPanel = () => {
  const location = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // activeSection state will now be updated on scroll as well as on click
  const [activeSection, setActiveSection] = useState(""); // Track active section
  // Add a state variable to control the Book Demo modal:
  const [openBookDemoModal, setOpenBookDemoModal] = useState(false);
  const boardFeaturesRef = useRef(null);
  const [showFeaturesPopup, setShowFeaturesPopup] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(true);

  const images = useMemo(
    () => [
      {
        id: 1,
        src: "https://content.qeeb.in/coltec/landing/interactive-display-for-modern-classrooms.webp",
        alt: "High-resolution interactive display designed for modern classrooms.",
      },
      {
        id: 2,
        src: "https://content.qeeb.in/coltec/landing/interactive-screen-classroom-collaborations.webp",
        alt: "Large interactive screen enhancing collaboration in classrooms.",
      },
      {
        id: 3,
        src: "https://content.qeeb.in/coltec/landing/interactive-tv-smart-for-classrooms.webp",
        alt: "Interactive TV for touch-enabled learning in smart classrooms.",
      },
      {
        id: 4,
        src: "https://content.qeeb.in/coltec/landing/interactive-touch-screen-for-hands-on-learning.webp",
        alt: "Interactive touch screen providing hands-on learning experiences.",
      },
      {
        id: 5,
        src: "https://content.qeeb.in/coltec/landing/interactive-touch-panel-for-classroom-use.webp",
        alt: "Interactive touch panel supporting immersive classroom lessons.",
      },
      {
        id: 6,
        src: "https://content.qeeb.in/coltec/landing/interactive-panel-price-for-schools.webp",
        alt: "Affordable interactive panel price options for schools and institutions.",
      },
    ],
    []
  );

  // Use Effect hook for rotating the images
  useEffect(() => {
    const cleanupInterval = startImageRotation(images, setCurrentImageIndex);
    return cleanupInterval; // Cleanup interval when component is unmounted
  }, [images]);

  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  // Function to handle the active link
  const handleSetActive = (section) => {
    setActiveSection(section);
    setMobileMenuOpen(false);
  };

  // New useEffect: update activeSection on scroll by checking section positions
  useEffect(() => {
    const handleScrollActiveSection = () => {
      // Adjust threshold as needed (in pixels)
      const threshold = 150;
      // Define the sections with their corresponding active name
      const sections = [
        { id: "smart-features", section: "smart-features" },
        { id: "benefits-section", section: "benefits" },
        { id: "board-features", section: "board-features" },
        { id: "details-section", section: "details" },
        { id: "about-us-section", section: "about-us" },
        { id: "faqs-section", section: "faqs" },
      ];

      // Check from the bottom-most section up so that the current section is the one whose top has passed the threshold
      for (let i = sections.length - 1; i >= 0; i--) {
        const sectionElement = document.getElementById(sections[i].id);
        if (sectionElement) {
          const rect = sectionElement.getBoundingClientRect();
          if (rect.top <= threshold) {
            setActiveSection(sections[i].section);
            return;
          }
        }
      }
      // Fallback: if none have reached the threshold, you may set a default (for example, the first section)
      setActiveSection("");
    };

    window.addEventListener("scroll", handleScrollActiveSection);
    // Call it once on mount in case the user is not at the top
    handleScrollActiveSection();
    return () =>
      window.removeEventListener("scroll", handleScrollActiveSection);
  }, []);

  const SLIDE_DURATION = 10000; // in ms
  const STEPS_PER_SLIDE = 3;

  // Current slide (0 or 1 here, but you can have more)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  // Progress (0..1) within the current 30s slide cycle
  const [progress, setProgress] = useState(0);

  // Track the start time of the current slide in a ref
  const startTimeRef = useRef(Date.now());

  useEffect(() => {
    let animationFrameId;

    const animate = () => {
      const now = Date.now();
      const elapsed = now - startTimeRef.current;
      let fraction = elapsed / SLIDE_DURATION; // 0..1

      if (fraction >= 1) {
        // Move to next slide
        const nextSlide = (currentSlideIndex + 1) % slides.length;
        setCurrentSlideIndex(nextSlide);

        // Reset for new slide
        startTimeRef.current = now;
        fraction = 0;
      }

      setProgress(Math.min(fraction, 1));
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [currentSlideIndex]);

  // Which step is currently active (0..2)
  const stepIndex = Math.floor(progress * STEPS_PER_SLIDE);

  // Get the active slide data
  const { steps } = slides[currentSlideIndex];
  // For the left side image, we show only the active step's image
  const activeStep = steps[stepIndex];

  // Helper: calculate the blue fill height (in pixels) for the connecting line below a given step circle
  const computeFillHeightForLine = (i) => {
    const containerHeight = 50; // Same as h-[50px]
    if (stepIndex > i) {
      // This step is complete – the line is fully filled.
      return containerHeight;
    } else if (stepIndex === i) {
      // Currently transitioning – fill proportionally.
      const fractionForCurrentStep = progress * STEPS_PER_SLIDE - stepIndex;
      return fractionForCurrentStep * containerHeight;
    } else {
      // Not yet reached – no fill.
      return 0;
    }
  };

  // --- Intersection Observer for "board-features" pop-up ---
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowFeaturesPopup(true);
            setLoaderVisible(true);
            setTimeout(() => {
              setLoaderVisible(false);
              setShowFeaturesPopup(false);
              // Unobserve the element to prevent further triggers
              observerInstance.unobserve(entry.target);
            }, 10000);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (boardFeaturesRef.current) {
      observer.observe(boardFeaturesRef.current);
    }

    return () => {
      if (boardFeaturesRef.current) {
        // eslint-disable-next-line
        observer.unobserve(boardFeaturesRef.current);
      }
    };
  }, []);

  return (
    <>
      <div>
        <SEO
          title="The Ultimate Smart Interactive Flat Panel – Engage, Collaborate & Elevate Learning"
          description="Revolutionize classroom learning with smart interactive flat panels. Experience seamless multi-touch collaboration, digital whiteboards, and AI-powered teaching tools for an engaging and future-ready education. Book Free Demo NOW!"
          keywords="Smart Interactive flat panel, Interactive Display, Interactive Panel, Interactive panel price, Interactive TV, Interactive Touch Panel, Interactive Screen, Interactive Touch Screen, Interactive Flat Panel"
          ogTitle="The Ultimate Smart Interactive Flat Panel – Engage, Collaborate & Elevate Learning"
          ogDescription="Revolutionize classroom learning with smart interactive flat panels. Experience seamless multi-touch collaboration, digital whiteboards, and AI-powered teaching tools for an engaging and future-ready education. Book Free Demo NOW!"
          ogImage="https://content.qeeb.in/coltec/landing/smart-interactive-flat-panel-for-classroom.webp"
          ogUrl="https://coltec-global.com/coltec-smart-interactive-flat-panel-for-classroom"
          canonicalUrl="https://coltec-global.com/coltec-smart-interactive-flat-panel-for-classroom"
          ogImageAlt="Best Smart Interactive flat panels for learning in classrooms"
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <div className="md:p-3">
          <div className="relative">
            <div>
              <img
                alt="Smart interactive flat panel for classroom learning and digital teaching."
                src="https://content.qeeb.in/coltec/landing/smart-interactive-flat-panel-for-classroom.webp"
                className="w-full md:h-auto h-[420px]"
              />
            </div>
            <div className="absolute top-0 md:mt-36 mt-[75px] flex flex-col items-center justify-center inset-x-0">
              <h1 className="lg:w-[900px] heading-t-font md:px-0 px-5 w-full md:text-center text-white text-[28px] md:text-[72px] leading-8 font-semibold md:leading-[74px]">
                D﻿on’t Settle for Ordinary <br />
                Interactive Flat Panels!
              </h1>
              <p className="lg:w-[920px] md:leading-[32px] w-full md:px-0 px-5 text-left xl:text-center text-white md:text-[30px] text-base font-normal mt-3 md:mt-4">
                Choose COLTEC’s{" "}
                <span className="text-[#00222F] font-bold">
                  Smart Interactive Board
                </span>{" "}
                - powered by the revolutionary Classroom Platform, to transform
                learning into a smarter, more engaging experience!
              </p>
            </div>
            <header
              className={`inset-x-0 top-0 z-50 ${
                isFixed
                  ? "fixed fadeInDown bg-white py-3 fixed-nav border-white/30 bg-gradient-radial backdrop-blur-md border-b"
                  : "absolute nav-text py-5"
              }`}
            >
              <nav
                aria-label="Global"
                className="flex items-center justify-between px-5 lg:px-5"
              >
                <div className="ml-[-10px]">
                  <a href="/">
                    <img
                      alt="Coltec Logo"
                      width="130"
                      height="28"
                      src={
                        isFixed
                          ? "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                          : "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                      }
                      className="md:h-6"
                    />
                  </a>
                </div>

                <div className="hidden lg:flex items-center lg:gap-x-12">
                  <div className="flex space-x-12">
                    <Link
                      to="smart-features"
                      smooth={true}
                      offset={-40}
                      duration={500}
                      className={`transition-all flex items-center space-x-3 text-center text-base font-normal leading-normal cursor-pointer ${
                        activeSection === "smart-features"
                          ? "text-orange-600"
                          : isFixed
                          ? "text-black hover:text-orange-600"
                          : "text-white hover:text-orange-600"
                      }`}
                      onClick={() => handleSetActive("smart-features")}
                    >
                      Features
                      <img
                        alt="Features Icon"
                        className={`${isFixed ? "" : "invert"} h-3 ml-5`}
                        src="https://content.qeeb.in/coltec/landing/arrow-icon.svg"
                      />
                    </Link>
                    <Link
                      to="board-features"
                      smooth={true}
                      spy={true} // Added spy to track the active section during scroll
                      offset={-40}
                      duration={500}
                      className={`transition-all text-center text-base font-normal leading-normal cursor-pointer ${
                        activeSection === "board-features"
                          ? "text-orange-600"
                          : isFixed
                          ? "text-black hover:text-orange-600"
                          : "text-white hover:text-orange-600"
                      }`}
                      onClick={() => handleSetActive("board-features")}
                    >
                      Whiteboard
                    </Link>
                    <Link
                      to="details-section"
                      smooth={true}
                      spy={true} // Added spy to track the active section during scroll
                      offset={-40}
                      duration={500}
                      className={`transition-all text-center text-base font-normal leading-normal cursor-pointer ${
                        activeSection === "details"
                          ? "text-orange-600"
                          : isFixed
                          ? "text-black hover:text-orange-600"
                          : "text-white hover:text-orange-600"
                      }`}
                      onClick={() => handleSetActive("details")}
                    >
                      System
                    </Link>
                  </div>
                  <Link
                    to="benefits-section"
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className={`transition-all text-center text-base font-normal leading-normal cursor-pointer ${
                      activeSection === "benefits"
                        ? "text-orange-600"
                        : isFixed
                        ? "text-black hover:text-orange-600"
                        : "text-white hover:text-orange-600"
                    }`}
                    onClick={() => handleSetActive("benefits")}
                  >
                    Benefits
                  </Link>
                  <Link
                    to="about-us-section"
                    smooth={true}
                    offset={-140}
                    duration={500}
                    className={`transition-all text-center text-base font-normal leading-normal cursor-pointer ${
                      activeSection === "about-us"
                        ? "text-orange-600"
                        : isFixed
                        ? "text-black"
                        : "text-white"
                    }`}
                    onClick={() => handleSetActive("about-us")}
                  >
                    About Us
                  </Link>
                  <Link
                    to="faqs-section"
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className={`transition-all text-center text-base font-normal leading-normal cursor-pointer ${
                      activeSection === "faqs"
                        ? "text-orange-600"
                        : isFixed
                        ? "text-black"
                        : "text-white"
                    }`}
                    onClick={() => handleSetActive("faqs")}
                  >
                    FAQs
                  </Link>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => setOpenBookDemoModal(true)}
                    className="mr-3 w-[130px] h-[34px] p-[5px] text-center cursor-pointer text-white bg-gradient-to-b from-[#538cff] to-[#538cff] rounded-lg border border-[#538cff]"
                  >
                    Book a Demo
                  </button>
                  <button
                    type="button"
                    onClick={() => setMobileMenuOpen(true)}
                    className={`-m-2.5 inline-flex items-center justify-center rounded-md block lg:hidden p-2.5 ${
                      isFixed ? "text-gray-400" : "text-white"
                    }`}
                  >
                    <span className="sr-only">Open main menu</span>
                    <Bars3Icon aria-hidden="true" className="h-8 w-8" />
                  </button>
                </div>
              </nav>
              {/* Mobile Menu */}
              <Dialog
                open={mobileMenuOpen}
                onClose={() => setMobileMenuOpen(false)}
                className="lg:hidden"
              >
                <div className="fixed inset-0 z-50" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                  <div className="flex items-center justify-between">
                    <Link to="/">
                      <img
                        alt="Logo"
                        src={
                          isFixed
                            ? "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                            : "https://content.qeeb.in/coltec/coltec-logo-black.svg"
                        }
                        className="h-6 w-auto"
                      />
                    </Link>
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-m-2.5 rounded-md p-2.5 text-gray-400"
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon aria-hidden="true" className="h-8 w-8" />
                    </button>
                  </div>
                  <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/25">
                      <div className="flex flex-col py-6 space-y-4">
                        <Link
                          to="smart-features"
                          smooth={true}
                          offset={-40}
                          duration={500}
                          className={`transition-all flex items-center space-x-3 text-center text-base font-normal leading-normal cursor-pointer ${
                            activeSection === "smart-features"
                              ? "text-orange-600"
                              : isFixed
                              ? "text-black hover:text-orange-600"
                              : "text-black hover:text-orange-600"
                          }`}
                          onClick={() => handleSetActive("smart-features")}
                        >
                          Features
                        </Link>

                        <Link
                          to="board-features"
                          smooth={true}
                          spy={true} // Added spy to track the active section during scroll
                          offset={-40}
                          duration={500}
                          className={`transition-all text-base font-normal leading-normal cursor-pointer ${
                            activeSection === "board-features"
                              ? "text-orange-600"
                              : isFixed
                              ? "text-black hover:text-orange-600"
                              : "text-black hover:text-orange-600"
                          }`}
                          onClick={() => handleSetActive("board-features")}
                        >
                          Whiteboard
                        </Link>
                        <Link
                          to="details-section"
                          smooth={true}
                          spy={true} // Added spy to track the active section during scroll
                          offset={-40}
                          duration={500}
                          className={`transition-all text-base font-normal leading-normal cursor-pointer ${
                            activeSection === "details"
                              ? "text-orange-600"
                              : isFixed
                              ? "text-black hover:text-orange-600"
                              : "text-black hover:text-orange-600"
                          }`}
                          onClick={() => handleSetActive("details")}
                        >
                          System
                        </Link>
                        <Link
                          to="benefits-section"
                          smooth={true}
                          offset={80}
                          duration={500}
                          className={`transition-all text-base font-normal leading-normal cursor-pointer ${
                            activeSection === "benefits"
                              ? "text-orange-600"
                              : isFixed
                              ? "text-black hover:text-orange-600"
                              : "text-black hover:text-orange-600"
                          }`}
                          onClick={() => handleSetActive("benefits")}
                        >
                          Benefits
                        </Link>

                        <Link
                          to="about-us-section"
                          smooth={true}
                          offset={-70}
                          duration={500}
                          className={`transition-all text-base font-normal leading-normal cursor-pointer ${
                            activeSection === "about-us"
                              ? "text-orange-600"
                              : isFixed
                              ? "text-black"
                              : "text-black"
                          }`}
                          onClick={() => handleSetActive("about-us")}
                        >
                          About Us
                        </Link>
                        <Link
                          to="faqs-section"
                          smooth={true}
                          offset={-70}
                          duration={500}
                          className={`transition-all text-base font-normal leading-normal cursor-pointer ${
                            activeSection === "faqs"
                              ? "text-orange-600"
                              : isFixed
                              ? "text-black"
                              : "text-black"
                          }`}
                          onClick={() => handleSetActive("faqs")}
                        >
                          FAQs
                        </Link>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </Dialog>
            </header>
          </div>
        </div>

        <div id="smart-features">
          <div className="benefits-bg">
            <div className="px-5 lg:px-8 lg:py-10 pt-10 max-w-7xl mx-auto">
              {/* <!-- Benefits content here --> */}
              <h2 className="w-full md:text-center mb-8 max-w-3xl mx-auto leading-9">
                <span className="text-black md:text-[30px] text-[24px] font-semibold">
                  See Why the COLTEC Smart Interactive Board is a Classroom{" "}
                </span>
                <span className="text-[#79adf3] md:text-[30px] text-[24px] font-semibold">
                  Favorite for Teachers & Students
                </span>
              </h2>
              {/* Image Container */}
              <div className="relative mt-[-15px]">
                {/* Render only the current image based on the index */}
                <img
                  alt={images[currentImageIndex].alt}
                  src={images[currentImageIndex].src}
                  className="fade-image visible pb-10 md:max-w-4xl lg:h-[640px] mx-auto"
                />
                <Swiper
                  spaceBetween={10}
                  slidesPerView={3}
                  grabCursor={true}
                  loop={true}
                  centeredSlides={false}
                  slidesPerGroupSkip={1}
                  keyboard={{
                    enabled: true,
                  }}
                  autoplay={{
                    delay: 9000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={true}
                  navigation={false}
                  modules={[Autoplay, Navigation, Scrollbar]}
                  className="mySwiper benefits-swiper lg:h-[230px] mt-[-120px] md:mt-[-280px]"
                >
                  {cardData.map((card) => (
                    <SwiperSlide key={card.id}>
                      <div className={`lg:w-[360px] h-[210px] relative `}>
                        <div className="mb-2">
                          <img
                            className="h-7"
                            src={card.icon}
                            alt={card.title}
                          />
                        </div>
                        <div className="flex justify-start items-center space-x-4">
                          <h3 className="text-[#08080] font-medium text-base">
                            {card.title} {card.text}
                          </h3>
                        </div>
                        <p className="pt-4 text-[12px] text-[#08080] font-medium">
                          {card.description}
                        </p>
                        <div
                          className={`lg:inline-block hidden ${card.border}`}
                        >
                          <img
                            alt="Line"
                            src="https://content.qeeb.in/coltec/landing/benefits-line.svg"
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div
          id="benefits-section"
          className="relative px-5 lg:px-8 py-10 max-w-7xl mx-auto"
        >
          <div>
            {/* The "slides container" stacked vertically */}
            <h2 className="w-full md:text-center mb-3 max-w-2xl mx-auto leading-9">
              <span className="text-black md:text-[30px] text-[24px] font-semibold">
                <span className="text-[#79adf3]">Revolutionize Learning </span>
                with Smart Interactive Flat Panels
              </span>
            </h2>
            <p className="md:text-center mx-auto max-w-3xl text-black mb-12">
              Unlock the future of education with cutting-edge technology that
              drives engagement, enhances focus, and fosters
              collaboration—making every lesson impactful and unforgettable.
            </p>
            {/* Render each slide as a full-screen panel */}
            <div className="grid md:grid-cols-2 lg:gap-20 gap-5 items-start">
              {/* Left column: Active step's image */}
              <div className="flex justify-center">
                <img
                  src={activeStep.image}
                  alt={activeStep.alt}
                  className="object-cover w-full h-auto rounded shadow"
                />
              </div>

              {/* Right column: vertical timeline & step content */}
              <div className="flex items-start justify-center">
                {/* Timeline (line + circles) */}
                <div className="relative flex flex-col items-center mr-6">
                  {/* Step circles: highlight only the active step */}
                  {steps.map((step, idx) => (
                    <div key={idx} className="flex flex-col items-center">
                      <div
                        className={`flex items-center justify-center ${
                          idx === stepIndex
                            ? "w-14 h-14 bg-[#3687fe] rounded-lg shadow-[0px_4px_16px_0px_rgba(203,211,222,0.60)] border"
                            : "w-14 h-14 bg-gradient-to-r from-[#fafbff] to-[#edf2ff] rounded-lg shadow-[0px_4px_16px_0px_rgba(203,211,222,0.60)] border border-[#d8e8ec]"
                        }`}
                      >
                        <img
                          src={step.icon}
                          alt={step.alt}
                          className={
                            idx === stepIndex ? "invert brightness-0" : ""
                          }
                        />
                      </div>
                      {/* Only render a connecting line if this is not the last step */}
                      {idx < steps.length - 1 && (
                        <div className="relative h-[50px] my-4 w-[2px] bg-gray-300">
                          <div
                            className="absolute top-0 left-0 w-[2px] bg-[#3687fe] transition-all duration-300"
                            style={{
                              height: `${computeFillHeightForLine(idx)}px`,
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* Step text */}
                <div className="flex flex-col md:space-y-[53px] space-y-[40px]">
                  {steps.map((step, idx) => (
                    <div key={idx}>
                      <h3
                        className={`text-xl font-semibold mb-2 ${
                          idx === stepIndex ? "text-[#3687fe]" : "text-black"
                        }`}
                      >
                        {step.title}
                      </h3>
                      <p className="text-[#494949] text-xs font-normal lg:w-[350px]">
                        {step.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="board-features"
          ref={boardFeaturesRef}
          className="px-5 lg:px-8 lg:py-10 pb-10 max-w-7xl mx-auto"
        >
          {/* <!-- Features content here --> */}
          <h2 className="max-w-2xl mx-auto w-full md:text-center mb-8 leading-9">
            <span className="text-black md:text-[30px] text-[24px] font-semibold">
              Discover the{" "}
              <span className="text-[#79adf3]">Power of Innovation</span>:{" "}
            </span>
            <span className="md:text-[30px] text-[24px] font-semibold">
              Features that Redefine COLTEC Interactive Board
            </span>
          </h2>
          {/* Bento grid layout for features */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:px-10">
            {/* Render each feature from featureData */}
            {featureData.map((feature) => (
              <div
                key={feature.id}
                className={`bg-white p-8 rounded-2xl ${feature.span}`}
              >
                <div className={`${feature.flex_class}`}>
                  <div>
                    <h3 className="text-lg font-medium">{feature.title}</h3>
                    <p className={`text-sm text-[#4A4A4A] mb-3`}>
                      {feature.description}
                    </p>
                  </div>
                  {feature.icon && (
                    <img
                      src={feature.icon}
                      alt={feature.title}
                      className="h-[150px] rounded-lg"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* --- Features Pop-Up Overlay --- */}
          {showFeaturesPopup && (
            <div className="flex absolute mt-[-400px] xl:ml-[-150px] z-1">
              <div
                className="relative slide-right cursor-pointer"
                onClick={() => setOpenBookDemoModal(true)}
              >
                <img
                  src="https://content.qeeb.in/coltec/landing/feature-slide-popup.webp"
                  alt="Features Pop Up"
                  className="w-[320px] h-[220px]"
                />
                {loaderVisible && (
                  <div className="absolute top-4 right-4 flex items-center justify-center">
                    <div class="feature-loader"></div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div id="details-section">
          {/* <!-- Details content here --> */}
          <div className="px-5 lg:px-8 lg:py-10 pb-10 max-w-7xl mx-auto">
            <h2 className="md:text-center max-w-2xl mx-auto md:text-3xl text-[24px] font-bold text-black">
              <span className="text-[#79adf3]">Revolutionize Learning</span>{" "}
              with Smart Interactive Boards
            </h2>
            <p className="md:text-center mx-auto max-w-3xl text-black md:mt-4">
              Unlock the future of education with cutting-edge technology that
              drives engagement, enhances focus, and fosters
              collaboration—making every lesson impactful and unforgettable.
            </p>

            {/* Feature Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0 mt-12 relative">
              {features.map((feature, index) => (
                <div
                  key={feature.id}
                  className={`relative md:p-8 py-6 ${feature.border}`}
                >
                  {feature.dot && (
                    <div className="hidden lg:block absolute right-[-5px] bottom-[-5px] z-[1]">
                      <img alt="Feature-dot" src={feature.dot} />
                    </div>
                  )}
                  <div className="text-4xl">
                    <img
                      alt={feature.title}
                      src={feature.icon}
                      className="h-[50px]"
                    />
                  </div>
                  <h3 className="mt-4 text-lg leading-6 font-semibold">
                    {feature.title}
                  </h3>
                  <p className="mt-2 text-xs text-gray-500">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="about-us-section">
          <div className="px-5 lg:px-8 lg:py-10 pb-10 max-w-7xl m-auto">
            {/* <!-- About Us content here --> */}
            <div className="mx-auto md:text-center md:px-0 max-w-3xl lg:mb-8 mb-2">
              <h2 className="text-2xl md:text-3xl font-semibold">
                <span className="text-[#79adf3]">
                  Revolutionizing Education
                </span>{" "}
                with Cutting-Edge{" "}
                <span className="font-bold">Smart Interactive Flat Panels</span>
              </h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="md:text-left">
                <p className="text-black mt-4">
                  Designed for ease and reliability, COLTEC’s interactive boards
                  empower teachers to seamlessly integrate technology, saving
                  time and boosting productivity. Our solutions guarantee
                  consistent performance and minimal disruptions in demanding
                  classrooms.
                </p>
                <p className="font-semibold mt-4">
                  Trusted by 5,000+ teachers and empowering 2M+ students
                  nationwide,
                </p>
                <p className="text-black">
                  COLTEC delivers innovative, reliable interactive boards. Our
                  robust products and dedicated support foster smarter,
                  connected classrooms where educators and students thrive.
                </p>
                <p className="text-black mt-4">
                  COLTEC transforms education through trust, reliability, and
                  user-friendly innovation.
                </p>
                <div className="w-[220px] mt-5 h-12 pl-[34px] pr-8 py-[11px] bg-gradient-to-r from-[#79adf3] to-[#3083ff] rounded-3xl shadow-[inset_0px_0.5px_0px_0px_rgba(255,255,255,0.32)] justify-start items-center gap-2.5 inline-flex">
                  <Link
                    onClick={() => setOpenBookDemoModal(true)}
                    className="cursor-pointer text-center text-white text-lg font-medium font-['Poppins'] leading-snug tracking-tight"
                  >
                    Request a Demo
                  </Link>
                </div>
              </div>
              <div>
                <img
                  src="https://content.qeeb.in/coltec/landing/interactive-panel-price-budget-friendly.webp"
                  alt="Interactive panel price tailored for budget-friendly classroom solutions."
                  className="w-full rounded-lg shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* <!-- Safety content here --> */}
          <SafetyComfort />
        </div>
        <div id="faqs-section">
          {/* <!-- FAQs content here --> */}
          <div className="px-5 lg:px-8 lg:py-10 pb-10 max-w-7xl m-auto landing-faq">
            <div className="max-w-2xl mx-auto md:text-center mb-8">
              <span className="text-[#79adf3] text-2xl md:text-[32px] font-semibold font-['Poppins'] leading-[48px]">
                Frequently
              </span>
              <span className="text-black md:text-[32px] text-2xl font-semibold font-['Poppins'] leading-[48px]">
                {" "}
                Asked Questions (FAQs)
              </span>
            </div>
            <FAQ data={faqData} />
          </div>
        </div>
        <div>
          <TestimonialCarousel />
        </div>
        <div className="py-10">
          <div className="lg:text-center px-5 md:px-0">
            <h3 className="md:text-[32px] text-2xl font-semibold leading-8 mb-8">
              We are Proud to{" "}
              <span className="text-[#79adf3] md:text-[32px] text-2xl font-semibold">
                Partner
              </span>{" "}
              with
            </h3>
          </div>
          <div className="max-w-7xl mx-auto">
            <div className="marquee">
              {clientImages.map((client, index) => (
                <img
                  key={index}
                  src={client.src}
                  alt={client.alt}
                  loading="lazy" // Enable lazy loading
                  className="md:h-20 h-16"
                />
              ))}
            </div>
          </div>
        </div>
        <div id="book-demo" className="px-6 lg:px-8 py-10 max-w-7xl m-auto">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="relative flex justify-center text-black md:text-[30px] text-2xl font-semibold font-['Poppins'] leading-[48px]">
              <img
                className="mt-[-6px] mr-2 h-7"
                alt="form-qoute-1"
                src="https://content.qeeb.in/coltec/landing/form-qoute-1.svg"
              />
              See it to Believe it
              <img
                className="mt-[20px] ml-2 h-7"
                alt="form-qoute-2"
                src="https://content.qeeb.in/coltec/landing/form-qoute-2.svg"
              />
            </h2>
            <div className="text-center mb-4">
              <span class="text-black md:text-[30px] text-2xl font-semibold font-['Poppins'] leading-[48px]">
                Request
              </span>
              <span class="text-[#7c50f5] md:text-[30px] text-2xl font-semibold font-['Poppins'] leading-[48px]">
                {" "}
              </span>
              <span class="text-[#79adf3] md:text-[30px] text-2xl font-semibold font-['Poppins'] leading-[48px]">
                Consultation{" "}
              </span>
              <span class="text-black md:text-[30px] text-2xl font-semibold font-['Poppins'] leading-[48px]">
                Now
              </span>
            </div>
          </div>
          <Landingform />
        </div>
        <div>
          <Footer />
        </div>
        {/* Render the Book a Demo Modal */}
        {/* --- Normal Modal Popup Implementation --- */}
        {openBookDemoModal && (
          <div className="fixed inset-0 mx-3 flex justify-center items-center z-50">
            {/* Backdrop */}
            <div
              className="fixed inset-0 bg-black opacity-50"
              onClick={() => setOpenBookDemoModal(false)}
            ></div>
            {/* Modal Content */}
            <div className="modal-landing-form rounded-lg shadow-lg z-10 relative w-full max-w-2xl mx-auto">
              <button
                onClick={() => setOpenBookDemoModal(false)}
                className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
              <div>
                <div className="max-w-2xl mx-auto px-8 pt-8">
                  <h2 className="relative md:text-center md:justify-center flex text-black md:text-2xl text-lg font-semibold font-['Poppins'] leading-[20px]">
                    <img
                      className="mt-[-5px] mr-2 h-4"
                      alt="form-qoute-1"
                      src="https://content.qeeb.in/coltec/landing/form-qoute-1.svg"
                    />
                    See it to Believe it
                    <img
                      className="mt-[10px] ml-2 h-4"
                      alt="form-qoute-2"
                      src="https://content.qeeb.in/coltec/landing/form-qoute-2.svg"
                    />
                  </h2>
                  <div className="mb-4 md:text-center">
                    <span class="text-black text-lg md:text-2xl font-semibold font-['Poppins'] leading-[20px]">
                      Request
                    </span>
                    <span class="text-[#7c50f5] md:text-2xl text-lg font-semibold font-['Poppins'] leading-[20px]">
                      {" "}
                    </span>
                    <span class="text-[#79adf3] md:text-2xl text-lg font-semibold font-['Poppins'] leading-[20px]">
                      Consultation{" "}
                    </span>
                    <span class="text-black md:text-2xl text-lg font-semibold font-['Poppins'] leading-[20px]">
                      Now
                    </span>
                  </div>
                </div>
                <div className="modal-iframe px-8">
                  <iframe
                    src="/modal-landing-form.html"
                    title="outer-iframe"
                    className="w-full xl:h-[320px] md:h-[320px] h-[475px]"
                    style={{ border: "none" }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default InteractiveFlatPanel;
