export const clientImages = [
  {
    src: "https://content.qeeb.in/coltec/landing/clients/gaudium-logo.svg",
    alt: "The Gaudium School",
  },
  {
    src: "https://content.qeeb.in/coltec/landing/clients/st-joseph-high-school.svg",
    alt: "ST Joseph High School",
  },
  {
    src: "https://content.qeeb.in/coltec/landing/clients/d-a-v-public-school.svg",
    alt: "D.A.V Public School",
  },
  {
    src: "https://content.qeeb.in/coltec/landing/clients/delhi-public-school.svg",
    alt: "Delhi Public School",
  },
  {
    src: "https://content.qeeb.in/coltec/landing/clients/aditya-vidyashram.svg",
    alt: "Aditya Vidyashram",
  },
  {
    src: "https://content.qeeb.in/coltec/landing/clients/school_emblem_of_don_bosco_matunga.svg",
    alt: "Don Bosco High School",
  },

  {
    src: "https://content.qeeb.in/coltec/landing/clients/shaheen-group-of-institutions.svg",
    alt: "Shaheen Group of Institutions",
  },
  {
    src: "https://content.qeeb.in/coltec/landing/clients/loyola-high-school-goa.svg",
    alt: "Loyola High School Goa",
  },
  {
    src: "https://content.qeeb.in/coltec/landing/clients/iit-madras-logo.svg",
    alt: "IIT Madras",
  },
];
