import { Link } from "react-router-dom";
const navigation = {
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/profile.php?id=61570213181020",
      icon: (props) => (
        <img
          alt="Facebook"
          width="32"
          height="32"
          src="https://content.qeeb.in/coltec/facebook-icon.png"
          {...props}
        />
      ),
    },
    {
      name: "Instagram",
      href: "#",
      icon: (props) => (
        <img
          alt="Instagram"
          width="32"
          height="32"
          src="https://content.qeeb.in/coltec/instagram-icon.png"
          {...props}
        />
      ),
    },
    {
      name: "X",
      href: "https://x.com/ColtecGlobal",
      icon: (props) => (
        <img
          alt="Twitter"
          width="32"
          height="32"
          src="https://content.qeeb.in/coltec/twitter-icon.png"
          {...props}
        />
      ),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/coltec-global/",
      icon: (props) => (
        <img
          alt="LinkedIn"
          width="32"
          height="32"
          src="https://content.qeeb.in/coltec/linkedin-icon.png"
          {...props}
        />
      ),
    },
  ],
};

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <footer aria-labelledby="footer-heading" className="bg-gray-950">
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-18 lg:px-8 lg:pt-30 landing-footer">
        <div className="xl:grid xl:grid-cols-12 xl:gap-8">
          <div className="col-span-5">
            <div className="xl:grid xl:grid-cols-3 xl:gap-0">
              <div className="flex flex-col space-y-4">
                <Link to="/" className="text-gray-300 text-sm hover:text-white">
                  Home
                </Link>
                <Link
                  to="/about-us"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  About Us
                </Link>
                <Link
                  to="/media"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Media
                </Link>
                <Link
                  to="/connect"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Connect
                </Link>
              </div>
              <div className="flex flex-col space-y-4 pt-4 xl:pt-0">
                <Link
                  to="/products"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Products
                </Link>
                <Link to="/" className="text-gray-300 text-sm hover:text-white">
                  Solutions
                </Link>
                <Link
                  to="/blogs"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Blogs
                </Link>
              </div>
              <div className="flex flex-col space-y-4 pt-4 xl:pt-0">
                <Link
                  to="/privacy-policy"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/cookies-policy"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Cookies policy
                </Link>
                
                <Link
                  to="/terms-of-use"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Terms of Use
                </Link>
                <Link
                  to="/e-waste-policy"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  E-Waste Policy
                </Link>
                <Link
                  to="/refund-return-policy"
                  className="text-gray-300 text-sm hover:text-white"
                >
                  Refund & Return Policy
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-3 xl:pl-5">
            <div className="mt-10 xl:mt-0">
              <div className="text-sm leading-6 text-gray-300 mb-2">
                <p className="text-white font-medium mb-2">Design Office</p>
                <p>
                  1014 - 155 Hillcrest Avenue, Mississauga, <br />
                  Canada ON L5B 3Z2
                </p>
              </div>
              <p className="text-sm leading-6 text-gray-300 mb-5">
                +1 (647) 564-6779
              </p>
            </div>
          </div>
          <div className="col-span-4 xl:pl-8">
            <div className="text-sm leading-6 text-gray-300 mb-2">
              <p className="text-white font-medium mb-2">
                Manufacturing and Regional Office
              </p>
              Survey No.75/A Gagan Pahad, Rajendra Nagar Municipality Ranga
              Reddy District, TS INDIA
            </div>
            <p className="text-sm leading-6 text-gray-300 mb-2">
              +91 1800 309 8822
            </p>
            <p className="text-sm leading-6 text-gray-300 mb-6">
              sales@coltec-global.com
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row md:justify-between md:items-center mt-8 border-t border-white/30 pt-8 sm:mt-10 lg:mt-12">
          <p className="text-sm leading-5 text-gray-400">
            &copy; {currentYear} Coltec Global All rights reserved.
          </p>
          <div className="flex space-x-6 mb-5 md:mb-0">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noreferrer"
                className="text-gray-500 hover:text-gray-400"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon aria-hidden="true" className="h-6 w-6" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
