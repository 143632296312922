// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

// 1) Create the context
const AuthContext = createContext(null);

// 2) AuthProvider component that wraps your entire app
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  // On first load, check localStorage to see if admin is already logged in
  useEffect(() => {
    const storedStatus = localStorage.getItem("adminLoggedIn");
    if (storedStatus === "true") {
      setIsLoggedIn(true);
      setIsSignInModalOpen(false); // Make sure the modal is closed if already logged in
    }
  }, []);

  // Helper methods to open/close modal
  const openSignInModal = () => {
    if (!isLoggedIn) {
      setIsSignInModalOpen(true);
    }
  };
  const closeSignInModal = () => setIsSignInModalOpen(false);

  // Simulate a successful login
  const login = () => {
    setIsLoggedIn(true);
    localStorage.setItem("adminLoggedIn", "true");
    closeSignInModal();
  };

  // Logout helper if you need it
  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("adminLoggedIn");
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        openSignInModal,
        closeSignInModal,
        isSignInModalOpen,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// 3) Custom hook to access AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
