import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
// Get all discount coupons
export const getAllCoupons = async () => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/ColtecAdmin/GetAllCoupons`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching coupons:", error);
    throw error;
  }
};
