import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
const getComments = async () => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/ColtecAdmin/GetComments`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching comments: ", error);
    throw error;
  }
};

export default getComments;
