import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
// Utility function to handle the API POST request for user registration
export const registerUser = async (formData) => {
  const url = `${baseUrl}/api/Registration/UserRegistration`;
  try {
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
      },
    });
    return response.data; // Assuming successful response returns the response data
  } catch (error) {
    console.error("Error registering user:", error);
    throw new Error("User registration failed");
  }
};
