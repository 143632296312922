export const fetchUserDetails = async () => {
  // Get base URL from .env file
  const baseUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(`${baseUrl}/api/ColtecAdmin/GetUserDetails`);
    if (!response.ok) {
      throw new Error("Failed to fetch user details");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    return [];
  }
};
