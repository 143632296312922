import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import CTA from "../../components/CTA";
import OurClients from "../../components/OurClients";
import CorporateModule from "../../components/CorporateSwiper";
import ScrollToTopButton from "../../components/ScrollTop";
import SEO from "../../components/SEO";
import Testimonials from "../../components/Testimonials";
import WhatsAppMsg from "../../components/WhatsApp";
import FAQ from "../../components/FAQ";
import { faqData } from "../../utils/educationFAQ";

const testimonials = [
  {
    image:
      "https://content.qeeb.in/coltec/testimonials/global-sainik-academy.jpg",
    name: "Sharan Sikenpore",
    altImage: "Coltec Customer Global Sainik Academy",
    designation: "Founder Chairman & CEO",
    description:
      "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/shaheen-group.jpg",
    name: "Mr Qursheed Ahmed",
    altImage: "Coltec Customer Shaheen Group",
    designation: "Academic Councellor",
    description:
      "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/gaudium-logo.png",
    name: "Sudeshna Chatterjee",
    altImage: "Coltec Customer Gaudium School",
    designation: "Head of School",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/iit-madras-logo.png",
    name: "Vikas Sitraman",
    altImage: "Coltec Customer IIT Madras",
    designation: "IIT Madras - Academic Councellor",
    description:
      "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/berkadia.jpg",
    name: "Raj Kumar",
    altImage: "Coltec Customer Berkadia",
    designation: "AV Head",
    description:
      "“COLTEC’s interactive displays are a cornerstone of our new training facilities. The high-quality visuals and intuitive touch controls have enhanced our employee training programs significantly. We are very pleased with the results.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/spring-filed.jpg",
    name: "Akash Awasti",
    altImage: "Coltec Customer Spring Filed",
    designation: "Vice Principal",
    description:
      "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
  },
  {
    image: "https://content.qeeb.in/coltec/testimonials/edify-education.png",
    name: "Kishore Pandit",
    altImage: "Coltec Customer Edify Education",
    designation: "Head of School",
    description:
      "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
  },
  {
    image:
      "https://content.qeeb.in/coltec/testimonials/the-dollar-business.png",
    name: "Ashok Tripathi",
    altImage: "Coltec Customer The Dollar Business",
    designation: "IT Head",
    description:
      "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
  },
];

const cslides = [
  {
    heading: "Writing Board",
    description:
      "COLTEC’s IWB-Integrated Writing Board enhances your writing experience with quick tools and shortcuts to make your writing smooth, effective, and fast. Enjoy the Power of Endless Colour & Content full Canvas​.",
    image: "https://content.qeeb.in/coltec/education/endless-whiteboarding.webp",
    altTag:
      "Coltec Interactive Flat Panel displaying an educational diagram on volcano anatomy, designed for engaging classroom learning and interactive teaching experiences.",
  },
  {
    heading: "Smart Tools",
    description: `Coltec's Smart Tools enhance teaching with intuitive features for seamless lesson integration. Engage students with interactive elements and streamline classroom management.`,
    image:
      "https://content.qeeb.in/coltec/education/endless-whiteboarding-2.webp",
    altTag:
      "Coltec Interactive Flat Panel showcasing smart tools like compass, calculator, content table, and split screen, designed for interactive and engaging classroom learning.",
  },
  {
    heading: "Instant Visualization",
    description: `Coltec's Smart Tools enables text to image and video conversion right from the writing board. This enables easy comprehension of concepts thoughts and ideas.`,
    image:
      "https://content.qeeb.in/coltec/education/classroom-instant-visualization.webp",
    altTag:
      "Coltec Interactive Flat Panel displaying an educational diagram on Newton's Law of Motion with a tree and falling apple, designed for engaging science lessons in classrooms.",
  },
  {
    heading: "Apps Support",
    description: `Experience latest applications on the smart interactive flat panel with high processing capability on android and windows OS.`,
    image: "https://content.qeeb.in/coltec/education/apps-support.webp",
    altTag:
      "Coltec Interactive Flat Panel showcasing seamless integration with apps like YouTube, Chrome, Android, Gmail, and Zoom for enhanced collaboration and productivity.",
  },
  // Add more slides as needed
];
const conslides = [
  {
    heading: "Anti-Glare Display",
    description:
      "Coltec's Anti-Glare Display ensures clear visibility from every angle, reducing eye strain and enhancing the viewing experience in any lighting condition. Teach and learn without distractions.",
    image: "https://content.qeeb.in/coltec/education/anti-glare-display.webp",
    altTag:
      "Coltec Interactive Flat Panel showcasing Comparison between Coltec's anti-glare display and a standard anti-glare display, highlighting superior clarity and vibrant visuals for classrooms and professional use.",
  },
  {
    heading: "Toughened Glass",
    description: `Coltec's Toughened Glass offers exceptional durability and safety, ensuring reliable performance in any classroom setting. Experience unmatched clarity and resilience with our innovative design.`,
    image:
      "https://content.qeeb.in/coltec/education/hardware-toughened-glass.webp",
    altTag:
      "Coltec Interactive Flat Panel with toughened glass, offering durability, safety, and exceptional clarity for classrooms and collaborative environments.",
  },
  {
    heading: "Multiple Sizes",
    description: `Choose from a wide variety of sizes starting from 65” up-to 98”. The sizes are designed as per the class seating capacity ranging from small, medium & large classrooms.`,
    image: "https://content.qeeb.in/coltec/education/multiple-sizes.webp",
    altTag:
      "Coltec Interactive Flat Panels available in multiple sizes ranging from 65 to 98 inches, designed to accommodate small, medium, and large classrooms for enhanced learning experiences.",
  },
];
const Education = () => {
  return (
    <>
      <SEO
        title="Interactive Flat Panels for Education"
        description="Transform your classroom into a dynamic, hands-on learning space with Coltec’s interactive flat panels—fostering creativity, engagement, and success."
        keywords="Interactive Flat Panel, Smart Classroom Display, Touch Screen Panel for Education, Digital Whiteboard, Interactive Display for Schools, 4K Ultra HD Interactive Panel, Interactive Touch Screen for Business, Education Technology Solutions, Interactive Flat Panel Display, Smart Board for Classrooms"
        ogTitle="Interactive Flat Panels for Education | Coltec Solutions"
        ogDescription="Transform your classroom into a dynamic, hands-on learning space with Coltec’s interactive flat panels—fostering creativity, engagement, and success."
        ogImage="https://content.qeeb.in/coltec/education/banner-img.webp"
        ogUrl="https://coltec-global.com/solutions/education"
        canonicalUrl="https://coltec-global.com/solutions/education"
        ogImageAlt="Interactive Flat Panels for Education"
        ogSiteName="Coltec Global"
        ogType="website"
      />
      <div className="bg-gray-900">
        <Header />
        <div className="relative">
          <Banner
            imgSrc="https://content.qeeb.in/coltec/education/banner-img.webp"
            altText="Intelligent classroom with COLTEC interactive flat panels, featuring Voice-AI and multimedia tools for modern teaching and learning."
            heading="Worlds 1st Intelligent Classroom Technology Provider"
            bannerHeight="md:h-dvh h-[85vh]"
            opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-neutral-liner z-0"
            headClasses="md:text-[37px] text-[29px] text-white max-w-2xl font-medium leading-[1.3]"
            paragraphTextClasses="md:text-base text-sm font-normal mt-3 text-white max-w-xl leading-[1.5]"
            positionClasses="absolute md:bottom-60 bottom-[8rem] left-0 lg:left-32"
            paragraphText="At COLTEC-GLOBAL, we are redefining modern spaces for teaching and learning. By empowering teachers and students with state-of-the-art visual interactive technology, our powerful interactive panels deliver platform-based, curated subject-specific multimedia content."
            paragraphTextTwo="Our patented Voice-AI technology transforms any space into a powerful knowledge powerhouse.​"
          />
          <div className="absolute md:bottom-36 bottom-[3rem] mx-w-7xl lg:px-8 px-6 left-0 lg:left-32 space-x-4 flex">
            <button className="border-white/20 bg-gradient-radial backdrop-blur-md px-5 py-3 border rounded-lg text-white hover:bg-white/5 uppercase text-xs md:text-sm">
              Explore more
            </button>
            <button className="border-white/20 bg-gradient-radial backdrop-blur-md px-5 py-3 border rounded-lg text-white hover:bg-white/5 uppercase md:text-sm text-xs flex items-center">
              Book a demo now
              <img
                alt="arrow-icon"
                className="h-[10px] ml-2"
                src="https://content.qeeb.in/coltec/arrow-white.svg"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="relative group">
            <img
              alt="Students solving math problems on a Coltec Interactive Flat Panel, designed for collaborative and engaging classroom learning experiences."
              src="https://content.qeeb.in/coltec/education/product-whiteboarding.webp"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Whiteboarding
              </h3>
              <p className="text-white max-w-xl text-sm">
                Our interactive flat panel simplifies teaching with easy-to-use
                whiteboarding features, making lessons more engaging and
                interactive.
              </p>
            </div>
          </div>
          <div className="relative group">
            <img
              alt="Coltec Interactive Flat Panel in a classroom displaying a volcano anatomy lesson and a live video lecture, enhancing interactive and hybrid learning experiences."
              src="https://content.qeeb.in/coltec/education/product-video-teaching.webp"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Video Teaching
              </h3>
              <p className="text-white max-w-xl text-sm">
                Elevate your video lessons with our interactive flat panel,
                offering sharp visuals and seamless integration for a more
                immersive and engaging learning experience.
              </p>
            </div>
          </div>
          <div className="relative group">
            <img
              alt="Teacher presenting a world map on screen time statistics using a Coltec Interactive Flat Panel, enhancing visual learning and classroom engagement."
              src="https://content.qeeb.in/coltec/education/product-presentation.webp"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Presentation
              </h3>
              <p className="text-white max-w-xl text-sm">
                Elevate student presentations with our interactive flat panel,
                offering dynamic visuals and intuitive tools that make
                presenting more engaging and impactful.
              </p>
            </div>
          </div>
          <div className="relative group">
            <img
              alt="Coltec Interactive Flat Panel in a modern classroom featuring voice-activated Qeeb AI assistant, designed for interactive and smart learning experiences."
              src="https://content.qeeb.in/coltec/education/product-all-in-one.webp"
              className="w-full md:h-[340px] rounded-md"
            />
            <div className="invisible group-hover:visible absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
              <h3 className="text-lg text-white mb-1 fomt-medium">
                Voice Control
              </h3>
              <p className="text-white max-w-xl text-sm">
                Enhance your teaching experience with voice control, allowing
                hands-free interaction with our display panel for a more fluid
                and efficient classroom environment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-20 py-10 max-w-7xl mx-auto">
          <CorporateModule slides={cslides} />
        </div>
      </div>
      <div className="bg-[#DADADA]">
        <div className="px-6 lg:px-8 lg:py-20 py-10 max-w-7xl mx-auto">
          <CorporateModule slides={conslides} />
        </div>
      </div>
      <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
        <FAQ data={faqData} />
      </div>
      <div className="bg-[#f5f5f5]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
          <Testimonials testimonials={testimonials} />
        </div>
      </div>
      <div className="bg-gray-50">
        <OurClients />
      </div>
      <CTA />
      <Footer />
      <ScrollToTopButton />
      <WhatsAppMsg />
    </>
  );
};

export default Education;
