export const faqData = [
  {
    question:
      "What is the average lifespan (hours of life) of a COLTEC Smart Interactive Flat Panel?",
    answer: `COLTEC Smart Interactive Boards are designed for enterprise-grade use cases.Lifespan: 50,000 to 60,000 hours.Ideal for 24x7 usage in classrooms, boardrooms, and collaborative environments.`,
  },
  {
    question:
      "What operating system (OS) options are available and supported by COLTEC?",
    answer: `
    <ul class="list-disc pl-5">
      <li>Android OS (Built-in):</li>
      </ul>
      <p>COLTEC Smart Interactive Boards come with built-in Android OS for seamless functionality and app integration. Featuring the latest Android 14 and powered by an Octa-Core Processor with up to 12GB RAM and 256GB storage capacity, they deliver unmatched performance for modern classrooms and enterprise use.</p>
      <ul class="list-disc pl-5">
      <li>Windows OS:</li>
      </ul>
      <p>Windows OS is supported through external devices like PCs or OPS (Open Pluggable Specification) modules for advanced applications. COLTEC also offers custom configurations for processor, RAM, and hard disk to meet specific enterprise-grade requirements.</p>
      <p>With cutting-edge OS options, including the latest Android 14 and customizable Windows configurations, COLTEC Smart Interactive Boards provide the flexibility, power, and innovation needed for dynamic educational and enterprise environments.</p>
    
      `,
  },
  {
    question:
      "What are the installation prerequisites for COLTEC Smart Interactive Flat panel?",
    answer: `
    <p>To ensure seamless installation and optimal performance, the following prerequisites must be met:</p>
    <ul class="list-disc pl-5">
      <li>Mounting Options:</li>
      <li>A stable wall mount for fixed installations on a brick wall or reinforced wall for maximum stability.</li>
      <li>A durable mobile stand for flexibility and portability, ideal for multi-room usage.</li>
      <li>Power Supply:</li>
      <li>A 6 AMP power module is required to provide consistent and safe power for the panel’s advanced functionalities.</li>
      <li>Internet Connectivity:</li>
       <li>Wi-Fi or LAN access is essential to utilize cloud-based features, apps, and seamless updates.</li>
    </ul>
     <p>With clear installation prerequisites and robust mounting options, COLTEC Smart Interactive Boards ensure a hassle-free setup and deliver a secure, reliable, and dynamic interactive experience in any environment.</p>
  `,
    imageUrl:
      "https://content.qeeb.in/coltec/landing/multi-touch-interactive-screen.png",
  },
  {
    question:
      "How About the Delivery, Installation, and Training for a COLTEC Smart Interactive Flat Panel?",
    imageUrl:
      "https://content.qeeb.in/coltec/landing/touch-enabled-interactive-panel.png",
    answer: `
    <p>COLTEC ensures a smooth and professional process for delivery, installation, and training to maximize your experience:</p>
    <ul class="list-disc pl-5">
      <li>Delivery Time:</li>
      <li>Professional & Secure Delivery: Your panel is delivered within 7-14 business days, with all necessary precautions to ensure the device arrives safely and in perfect condition.</li>
      <li>Installation Time:</li>
      <li>Trained Professional Technicians: Certified experts handle installation, ensuring proper setup and seamless functionality.</li>
      <li>Installation is typically completed in 1-2 hours, including hardware setup and basic configuration.</li>
      <li>Configuration and Training:</li>
       <li>Comprehensive Training Sessions: Our Dedicated Customer Success Team provides personalized training, typically lasting 2-3 hours, to help educators and users understand and utilize all features effectively.</li>
      <li>Ongoing Support: The team remains available for after-sales assistance, ensuring long-term satisfaction and a smooth user experience.</li>
    </ul>
     <p>With professional delivery, expert installation, and tailored training, COLTEC Smart Interactive Boards ensure a hassle-free setup and empowering experience, making your classrooms or collaboration spaces future-ready.</p>
  `,
  },
  {
    question:
      "What﻿ is the Maintenance Cost and Sustainability of COLTEC Smart Interactive Flat Panels?",
    imageUrl:
      "https://content.qeeb.in/coltec/landing/cutting-edge-interactive-flat-panel.png",
    answer: `
        <p><b>COLTEC Smart Interactive Boards are designed for durability, efficiency, and sustainability, keeping maintenance costs low and ensuring long-term reliability:</b></p>
        <ul class="list-disc pl-5">
          <li>Low Maintenance Costs:</li>
          <li>Built with toughened glass and robust materials to withstand daily use, minimizing the need for repairs.</li>
          <li>Regular software updates are included at no additional cost.</li>
          <li>Energy Efficiency:</li>
          <li>Low Power Consumption: Designed to operate efficiently, reducing energy usage and lowering operational costs.</li>
          <li>Eco-friendly design supports sustainable practices in classrooms and offices.</li>
           <li>Minimal Cleaning Requirements:</li>
          <li>Easy-to-clean screens using non-abrasive cleaning supplies ensure longevity without extra effort.</li>
          <li>Optional Maintenance Plans:</li>
          <li>Annual maintenance plans are available to provide additional peace of mind, covering hardware checks and extended support.
With low maintenance needs, energy-efficient technology, and durable design, COLTEC Smart Interactive Boards offer a sustainable and cost-effective solution for modern classrooms and workspaces</li>
        </ul>
      `,
  },
];
