import React, { useEffect, useState, useContext } from "react";
import Header from "../../components/Header";
import FAQ from "../../components/FAQ";
import { faqData } from "../../utils/productFAQ";
import Footer from "../../components/Footer";
import ScrollToTopButton from "../../components/ScrollTop";
import WhatsAppMsg from "../../components/WhatsApp";
import { UserContext } from "../../utils/UserContext";
import SEO from "../../components/SEO";
import { deleteCartProduct } from "../../utils/deleteCartProduct";
import { showToast } from "../../utils/toaster";
import { HiTrash } from "react-icons/hi";
import { sendQuotation } from "../../utils/sendQuotation";
import { validateDiscountCoupon } from "../../utils/validateDiscountCoupon";
import { Link } from "react-router-dom";

// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;

const formatPrice = (price) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(price);
};

const ProductsCart = () => {
  const { user } = useContext(UserContext); // Get user from context
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);
  const [updatingItems, setUpdatingItems] = useState([]); // To track items being updated
  const [sendingEnquiry, setSendingEnquiry] = useState(false); // To track enquiry sending state

  // New states for coupon
  const [couponInput, setCouponInput] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  // New state for terms acceptance and modal visibility
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  useEffect(() => {
    const fetchCartData = async () => {
      const userEmail = user?.email;
      try {
        const response = await fetch(
          `${baseUrl}/api/Coltec/GetCartDetailsByEmail?Email=${encodeURIComponent(
            userEmail
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch cart data");
        }

        const data = await response.json();
        console.log("Fetched cart data:", data); // Debugging line

        // Ensure cartData is always an array
        const cartItems = Array.isArray(data) ? data : [data];
        setCartData(cartItems);

        // Calculate total price
        const totalAmount = cartItems.reduce(
          (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
          0
        );
        setTotal(totalAmount);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCartData();
  }, [user]);

  const handleDelete = async (id) => {
    try {
      await deleteCartProduct(id); // Call the delete function from utils
      const newCartData = cartData.filter((item) => item.id !== id);
      setCartData(newCartData); // Remove the item from state

      // Recalculate total after deletion using the updated cart data
      const newTotal = newCartData.reduce(
        (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
        0
      );
      setTotal(newTotal);

      // Show success toaster message
      showToast("Product removed from cart successfully!", "success");
    } catch (error) {
      setError("Failed to delete product");
      showToast("Failed to remove product from cart", "error");
    }
  };

  const handleQuantityChange = async (id, currentQuantity, action) => {
    const newQuantity =
      action === "increment" ? currentQuantity + 1 : currentQuantity - 1;

    // Prevent quantity from going below 1
    if (newQuantity < 1) return;

    // Find the item to update
    const itemToUpdate = cartData.find((item) => item.id === id);
    if (!itemToUpdate) {
      showToast("Product not found in cart", "error");
      return;
    }

    // Add item to updatingItems to show loading state
    setUpdatingItems((prev) => [...prev, id]);

    try {
      // Make API call to update cart product
      const response = await fetch(`${baseUrl}/api/Coltec/UpdateCartProduct`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id, // Product ID in the cart
          quantity: newQuantity,
          email: user.email, // User's email
          price: itemToUpdate.price,
          name: itemToUpdate.productName, // Product name
          imageUrl: itemToUpdate.imageUrl, // Product image URL
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update cart");
      }

      const data = await response.json();

      if (data.status === 1) {
        // Update cartData state
        const updatedCartData = cartData.map((item) =>
          item.id === id ? { ...item, quantity: newQuantity } : item
        );
        setCartData(updatedCartData);

        // Recalculate total using the updated cart data
        const newTotal = updatedCartData.reduce(
          (acc, item) => acc + (item.price || 0) * (item.quantity || 0),
          0
        );
        setTotal(newTotal);

        // Show success toaster message
        showToast("Cart updated successfully!", "success");
      } else {
        throw new Error(data.message || "Failed to update cart");
      }
    } catch (error) {
      setError(error.message);
      showToast("Failed to update cart", "error");
    } finally {
      // Remove item from updatingItems
      setUpdatingItems((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  // New function to handle applying coupon
  const handleApplyCoupon = async () => {
    if (!couponInput) {
      showToast("Please enter a coupon code", "error");
      return;
    }
    try {
      const couponResponse = await validateDiscountCoupon(couponInput);
      if (couponResponse.status === 1) {
        setAppliedCoupon(couponResponse);
        showToast(couponResponse.message, "success");
      } else {
        setAppliedCoupon(null);
        showToast(couponResponse.message || "Invalid coupon", "error");
      }
    } catch (error) {
      showToast("Failed to apply coupon", "error");
    }
  };

  // Calculate final total after discount
  const calculateFinalTotal = () => {
    if (
      appliedCoupon &&
      appliedCoupon.status === 1 &&
      appliedCoupon.percentage
    ) {
      const discountAmount = (total * appliedCoupon.percentage) / 100;
      return total - discountAmount;
    }
    return total;
  };

  // Calculate discount amount
  const calculateDiscountAmount = () => {
    if (
      appliedCoupon &&
      appliedCoupon.status === 1 &&
      appliedCoupon.percentage
    ) {
      return (total * appliedCoupon.percentage) / 100;
    }
    return 0;
  };

  // New function to handle Send Enquiry button click
  const handleSendEnquiry = async () => {
    setSendingEnquiry(true); // Set loading state

    // Option 1: Check at the start and refresh immediately if no products in cart.
    if (!cartData || cartData.length === 0) {
      window.location.reload();
      return;
    }

    // Check if user has accepted terms and conditions
    if (!acceptedTerms) {
      showToast("Please accept the terms and conditions", "error");
      setSendingEnquiry(false);
      return;
    }

    try {
      // Create a comma-separated string of product IDs from the cart data
      const productIds = cartData.map((item) => item.id).join(",");
      // Send the coupon code if a valid coupon is applied; otherwise, send an empty string.
      const discountCoupon =
        appliedCoupon && appliedCoupon.status === 1 ? couponInput : "";
      // Determine the total price to send:
      // Calculate pre GST total (after discount, if applicable)
      const preGSTTotal =
        appliedCoupon && appliedCoupon.status === 1
          ? calculateFinalTotal()
          : total;
      // Calculate the final (GST-inclusive) total using the multiplier 1.18
      const grandTotal = preGSTTotal * 1.18;
      // Convert to fixed 2 decimal places for sending
      const totalPriceToSend = grandTotal.toFixed(2);

      // Call sendQuotation with the proper total price value
      const data = await sendQuotation(
        user.email,
        productIds,
        discountCoupon,
        totalPriceToSend
      );
      // Show a toast based on the API response status
      if (data.status === -1) {
        showToast(data.message, "error");
      } else {
        showToast("Enquiry sent successfully!", "success");
      }
      console.log("SendQuotation response:", data);
      // Option 2: Alternatively, after the enquiry you may want to refresh the page if the cart is empty.
      // If your enquiry call clears the cart from the backend and you update your state accordingly,
      // then checking cartData here and refreshing the page is appropriate.
      if (!cartData.length) {
        window.location.reload();
      }
    } catch (error) {
      showToast(`Failed to send enquiry: ${error.message}`, "error");
      console.error("SendQuotation error:", error);
    } finally {
      setSendingEnquiry(false); // Reset loading state
    }
  };

  // Define values for displaying the cart summary.
  // Here, the final total (incl. GST) is preGST * 1.18.
  const preGSTAmount =
    appliedCoupon && appliedCoupon.status === 1 ? calculateFinalTotal() : total;
  const gstAmount = preGSTAmount * 0.18; // 18% GST
  const grandTotal = preGSTAmount * 1.18; // Final total including GST

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <div className="spin-loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <>
      <div className="about-us-page pt-10">
        <SEO
          title="Discover Coltec’s Interactive Flat Panels & Solutions"
          description="Explore Coltec's range of interactive flat panels and collaboration solutions, designed to enhance engagement and productivity in both education and business environments."
          keywords="interactive flat panels,touchscreen displays,digital whiteboards,collaboration solutions,educational technology, corporate training displays, zero bonding touch technology,multi-touch panels,remote learning solutions,hybrid classrooms,digital signage,interactive display supplier in India,best interactive flat panel for schools,business collaboration tools,high-definition interactive displays,teaching software solutions,anti-glare interactive screens, smart classroom technology"
          ogTitle="Coltec Global | Innovative Interactive Displays & Collaboration Solutions"
          ogDescription="Discover the full range of Coltec interactive flat panels, designed to revolutionize presentations, learning, and collaboration for modern classrooms and workplaces."
          ogImage="https://content.qeeb.in/coltec/products/banner-product-three.webp"
          ogUrl="https://coltec-global.com/products"
          canonicalUrl="https://coltec-global.com/products"
          ogImageAlt="Coltec Global Product Lineup of Interactive Flat Panels"
          ogSiteName="Coltec Global"
          ogType="website"
        />
        <Header />
        <div className="px-6 lg:px-8 lg:pt-14 pt-10 max-w-7xl mx-auto">
          <h1 className="text-black text-[32px] font-normal  leading-[48px]">
            Your Cart
          </h1>
        </div>
        <div className="px-6 lg:px-8 pt-10 max-w-7xl mx-auto">
          {cartData.length > 0 ? (
            <>
              <div className="cart-items bg-white rounded-[40px] border-2 border-black p-6">
                <div className="cart-item md:grid grid-cols-12 gap-5 mb-6 hidden">
                  <div className="item-details col-span-4">
                    <h3 className="text-black text-xl  font-normal  leading-[38.40px] mb-5">
                      Products
                    </h3>
                  </div>
                  <div className="quantity col-span-2 text-xl text-gray-600">
                    <h3 className="text-black  font-normal  leading-[38.40px] mb-5">
                      Quantity
                    </h3>
                  </div>
                  <div className="price col-span-2 text-xl font-semibold text-gray-800">
                    <h3 className="text-black  font-normal  leading-[38.40px] mb-5">
                      Unit Price
                    </h3>
                  </div>
                  <div className="price col-span-2 text-xl font-semibold text-gray-800">
                    <h3 className="text-black  font-normal  leading-[38.40px] mb-5">
                      Total Price
                    </h3>
                  </div>
                  <div className="action col-span-2">
                    <h3 className="text-black  font-normal text-xl leading-[38.40px] mb-5">
                      Actions
                    </h3>
                  </div>
                </div>
                <div className="space-y-6">
                  {cartData.map((item) => {
                    // Use productNameRaw with fallbacks
                    const productNameRaw =
                      item.productName || "Unnamed Product";
                    const [productName, panelSize] = productNameRaw.includes(
                      "|"
                    )
                      ? productNameRaw.split("|").map((str) => str.trim())
                      : [productNameRaw, ""];

                    return (
                      <div
                        key={item.id}
                        className="cart-item grid grid-cols-12 md:gap-5 gap-3"
                      >
                        <div className="item-details md:col-span-4 col-span-full">
                          <div className="flex items-center space-x-5">
                            <div className="bg-white rounded-2xl shadow-[0px_4px_20px_0px_rgba(13,13,13,0.15)] border p-4">
                              <img
                                src={item.imageUrl}
                                alt={productName}
                                className="w-32 h-32 object-contain rounded-lg"
                              />
                            </div>
                            <div>
                              <h3 className="text-lg font-medium">
                                {productName}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {panelSize}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="quantity md:col-span-2 col-span-6 text-sm text-gray-600">
                          <div className="flex items-center md:h-32 space-x-4">
                            <div className="border border-black px-6 py-0.5 rounded-full flex items-center">
                              <button
                                className="text-lg focus:outline-none"
                                onClick={() =>
                                  handleQuantityChange(
                                    item.id,
                                    item.quantity,
                                    "decrement"
                                  )
                                }
                                disabled={
                                  item.quantity === 1 ||
                                  updatingItems.includes(item.id)
                                }
                              >
                                -
                              </button>
                              <span className="mx-4">{item.quantity}</span>
                              <button
                                className="text-lg focus:outline-none"
                                onClick={() =>
                                  handleQuantityChange(
                                    item.id,
                                    item.quantity,
                                    "increment"
                                  )
                                }
                                disabled={updatingItems.includes(item.id)}
                              >
                                {updatingItems.includes(item.id) ? "+" : "+"}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="price md:col-span-2 col-span-5 text-lg font-semibold text-gray-800">
                          <div className="flex items-center md:h-32 space-x-4">
                            {formatPrice(item.price || 0)}
                          </div>
                        </div>
                        <div className="price md:col-span-2 col-span-5 text-lg font-semibold text-gray-800">
                          <div className="flex items-center md:h-32 space-x-4">
                            {formatPrice(
                              (item.price || 0) * (item.quantity || 0)
                            )}
                          </div>
                        </div>

                        <div className="action md:col-span-2 col-span-5">
                          <div className="flex items-center md:h-32 space-x-4">
                            <button
                              onClick={() => handleDelete(item.id)}
                              className="text-black hover:text-red-700"
                            >
                              <HiTrash className="text-xl" />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="cart-summary bg-gray-100 p-6 mt-6 rounded-md">
                <div className="flex justify-between md:text-lg">
                  <span>Subtotal</span>
                  <span>{formatPrice(total)}</span>
                </div>
                <div className="flex justify-between md:text-lg mt-2">
                  <span>Shipping</span>
                  <span>Calculated at next step</span>
                </div>
                {/* Terms & Conditions Checkbox Section */}
                <div className="flex justify-end mt-2">
                  <label className="flex space-x-1 text-sm items-center">
                    <input
                      type="checkbox"
                      checked={acceptedTerms}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                      required
                      className="mr-3 border border-gray-200 rounded w-5 h-5"
                    />
                    I accept the{" "}
                    <span
                      onClick={() => setShowTermsModal(true)}
                      className="text-blue-600 underline cursor-pointer"
                    >
                      terms and conditions
                    </span>
                    .
                  </label>
                </div>
                {/* Coupon section */}
                <div className="coupon-section mt-2 flex justify-between items-center">
                  <div>
                    <span className="md:text-lg">Discount Offer</span>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={couponInput}
                      onChange={(e) => setCouponInput(e.target.value)}
                      placeholder="Enter coupon code"
                      className="coupon-input py-2 px-3 border-gray-200 border rounded-full"
                    />
                    <button
                      onClick={handleApplyCoupon}
                      className="apply-coupon-button ml-2 bg-blue-500 hover:bg-blue-600 rounded-full text-white py-2 px-4"
                    >
                      Apply Coupon
                    </button>
                  </div>
                </div>
                {appliedCoupon && appliedCoupon.status === 1 ? (
                  <>
                    <div className="flex justify-between md:text-lg mt-2">
                      <span>Discount ({appliedCoupon.percentage}% off)</span>
                      <span>-{formatPrice(calculateDiscountAmount())}</span>
                    </div>
                    <div className="flex justify-between md:text-lg mt-2">
                      <span>Pre GST Total</span>
                      <span>{formatPrice(preGSTAmount)}</span>
                    </div>
                    <div className="flex justify-between md:text-lg mt-2">
                      <span>GST (18%)</span>
                      <span>{formatPrice(gstAmount)}</span>
                    </div>
                    <div className="flex justify-between md:text-2xl font-semibold mt-4">
                      <span>Total (incl. GST)</span>
                      <span>{formatPrice(grandTotal)}</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex justify-between md:text-lg mt-2">
                      <span>Pre GST Total</span>
                      <span>{formatPrice(total)}</span>
                    </div>
                    <div className="flex justify-between md:text-lg mt-2">
                      <span>GST (18%)</span>
                      <span>{formatPrice(total * 0.18)}</span>
                    </div>
                    <div className="flex justify-between md:text-2xl font-semibold mt-4">
                      <span>Total (incl. GST)</span>
                      <span>{formatPrice(total * 1.18)}</span>
                    </div>
                  </>
                )}
                <div className="text-center mt-6">
                  <button
                    onClick={handleSendEnquiry} // Attach the click handler
                    className={`bg-blue-600 text-white py-2 px-6 rounded-full ${
                      sendingEnquiry
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-blue-700"
                    }`}
                    disabled={sendingEnquiry} // Disable button while sending
                  >
                    {sendingEnquiry ? "Sending..." : "Send Enquiry"}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-center flex-col text-center items-center pb-8">
              <p className="text-xl font-mono mb-6">
                Your cart is empty.
                <br /> Please click to Continue Shopping.
              </p>
              <Link
                to="/products"
                className="hover:bg-orange-500 text-center text-white py-2.5 font-semibold bg-orange-400 px-4 rounded-xl"
              >
                Continue Shopping
              </Link>
            </div>
          )}
        </div>
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
          <FAQ data={faqData} />
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
      {/* Terms & Conditions Modal */}
      {showTermsModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={() => setShowTermsModal(false)}
          ></div>
          <div className="bg-white relative rounded-xl z-10 max-w-lg w-full">
            <h2 className="text-xl font-semibold mb-4 px-6 pt-6">
              Terms & Conditions for Checkout
            </h2>
            <div className="text-sm overflow-y-auto max-h-[450px] p-6">
              <p>
                <strong>1. Pricing:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  All prices displayed on the website are inclusive of GST.
                </li>
              </ul>
              <p>
                <strong>2. Delivery Charges:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  Free Delivery is applicable for orders of 5 or more units.
                </li>
                <li>
                  For orders below 5 units, delivery charges will be calculated
                  based on your pincode and added at checkout.
                </li>
                <li>
                  Delivery charges will be displayed clearly before payment
                  confirmation.
                </li>
              </ul>
              <p>
                <strong>3. Warranty:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  All COLTEC IFPDs come with a 3-year manufacturer’s warranty
                  for your peace of mind.
                </li>
              </ul>
              <p>
                <strong>4. Delivery Timeline:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  Orders will be delivered within one week after payment
                  confirmation and acknowledgment.
                </li>
                <li>
                  Delivery timelines may vary based on the shipping location and
                  pincode.
                </li>
              </ul>
              <p>
                <strong>5. Installation:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  Customers can request installation services after the item is
                  delivered by contacting our support team.
                </li>
                <li>Basic installation is included in the purchase price.</li>
              </ul>
              <p>
                <strong>6. Training:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  On-site training is included with every purchase to ensure
                  smooth setup and operation of the IFPD.
                </li>
              </ul>
              <p>
                <strong>7. Support Services:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  Post-purchase technical support is available for setup,
                  troubleshooting, and usage guidance.
                </li>
              </ul>
              <p>
                <strong>8. Payment Terms:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>Payments must be made in full at the time of checkout.</li>
                <li>
                  We accept all major credit cards, debit cards, net banking,
                  and UPI payments.
                </li>
              </ul>
              <p>
                <strong>9. Order Processing:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  Orders are processed within 1-2 business days of payment
                  confirmation.
                </li>
              </ul>
              <p>
                <strong>10. Cancellations and Refunds:</strong>
              </p>
              <ul className="list-disc ml-6 mb-2">
                <li>
                  Orders can be canceled within 24 hours of purchase for a full
                  refund.
                </li>
                <li>
                  Refunds will not be issued once the product has been
                  dispatched.
                </li>
                <li>
                  For defective or damaged products, replacements will be
                  provided as per our return policy.
                </li>
              </ul>
              <p>
                <strong>11. Bulk Orders:</strong>
              </p>
              <ul className="list-disc ml-6">
                <li>
                  For bulk purchases of 10 or more units, please contact our
                  sales team for customized pricing and delivery options.
                </li>
              </ul>
            </div>
            <div className="absolute top-5 right-4 text-right">
              <button
                onClick={() => setShowTermsModal(false)}
              >
                <img
                      alt="close-icon"
                      className="w-6"
                      src="https://content.qeeb.in/coltec/close-icon.svg"
                    />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsCart;
