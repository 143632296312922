import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
const approveComment = async (commentId) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/ColtecAdmin/ApproveComment?CommentId=${commentId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error approving comment:", error);
    throw error;
  }
};

export default approveComment;
