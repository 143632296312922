export const getBlogDetails = async (blogId) => {
  // Get base URL from .env file
  const baseUrl = process.env.REACT_APP_API_URL;
  const url = `${baseUrl}/api/Coltec/GetBlogDetails?blogId=${blogId}`;

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch blog details");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching blog details:", error);
    throw error;
  }
};
