import React, { useEffect, useState } from "react";
import WhatsApp from "react-whatsapp";

const WhatsAppMsg = () => {
  const [showIcon, setShowIcon] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [sourceUrl, setSourceUrl] = useState("");

  useEffect(() => {
    // Set the current URL from the browser
    if (typeof window !== "undefined") {
      setSourceUrl(window.location.href);
    }

    // Show the WhatsApp icon after 3 seconds
    const iconTimer = setTimeout(() => {
      setShowIcon(true);
    }, 3000);

    // Show the badge after 5 seconds and automatically open the popup
    const badgeTimer = setTimeout(() => {
      setShowBadge(true);
      setShowPopup(true);
    }, 5000);

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(iconTimer);
      clearTimeout(badgeTimer);
    };
  }, []);

  // Close the popup when the user clicks the close button
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showIcon && (
        <div className="fixed bottom-8 right-6 z-[10] p-2 w-11 h-11 flex justify-center items-center rounded-full bg-green-500 hover:bg-green-600 cursor-pointer">
          <div className="relative">
            {/* WhatsApp Link with dynamic message */}
            <WhatsApp
              number="+917075208796"
              message={`${sourceUrl} Hey COLTEC`}
              element="div"
              className="flex justify-center items-center"
            >
              <img
                alt="WhatsApp Logo"
                src="https://content.qeeb.in/coltec/what's-app-logo.svg"
                className="w-[20px] h-[20px]"
              />
            </WhatsApp>

            {/* Show the badge once showBadge = true */}
            {showBadge && (
              <span className="absolute font-mono -top-3 -right-3 bg-red-600 text-white text-xs w-4 h-4 flex items-center justify-center rounded-full">
                1
              </span>
            )}

            {/* Show the popup automatically once the badge is shown, until user closes it */}
            {showPopup && (
              <div className="absolute top-1/2 -translate-y-1/2 -left-[210px] w-[185px] bg-white text-gray-800 text-sm p-3 rounded-xl shadow-md">
                <div className="flex items-start justify-between">
                  <p className="pr-2 text-xs font-medium">
                    <span className="text-xs font-light">Need Help? </span>Chat
                    with us.
                  </p>
                  {/* Close button */}
                  <button
                    onClick={handleClosePopup}
                    className="absolute right-[-8px] top-[-8px] bg-white rounded-full p-1 flex justify-center items-center text-gray-500 hover:text-gray-800 font-bold shadow-lg w-6 h-6"
                  >
                    ✕
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default WhatsAppMsg;
