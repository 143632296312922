import React, { useEffect, useState } from "react";
import getComments from "../utils/getComments";
import approveComment from "../utils/approveComment";
import deleteComment from "../utils/deleteComment";
import { formatDateDDMMMYYYY } from "../utils/dateHelpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PiCheckCircle } from "react-icons/pi";
import { LuTrash2 } from "react-icons/lu";

const CommentDetails = () => {
  // Store all comments here
  const [allComments, setAllComments] = useState([]);
  // The comments visible in the table
  const [visibleComments, setVisibleComments] = useState([]);
  // How many comments to show at once
  const [visibleCount, setVisibleCount] = useState(25);
  // Track loading state
  const [loading, setLoading] = useState(false);

  const [selectedComment, setSelectedComment] = useState(null);

  const MAX_LENGTH = 35; // Adjust as needed

  useEffect(() => {
    const fetchAllComments = async () => {
      setLoading(true);
      try {
        const data = await getComments();
        // (Optional) Sort by createdAt descending if you want the latest on top
        const sorted = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        // For demonstration, we add an "isApproved" field
        const updatedData = sorted.map((comment) => ({
          ...comment,
          isApproved: false,
        }));
        setAllComments(updatedData);
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllComments();
  }, []);

  // Whenever allComments or visibleCount changes, update the visible slice
  useEffect(() => {
    setVisibleComments(allComments.slice(0, visibleCount));
  }, [allComments, visibleCount]);

  // Function to open modal and set the selected comment
  const handleOpenModal = (comment) => {
    setSelectedComment(comment);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedComment(null);
  };

  // Handle infinite scroll
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // If scrolled to bottom (with a little offset)
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      // If we still have more comments to show
      if (visibleCount < allComments.length) {
        setVisibleCount((prev) => prev + 25);
      }
    }
  };

  // Handle comment approval
  const handleApproveComment = async (commentId) => {
    try {
      const response = await approveComment(commentId);
      if (response.status === 1) {
        toast.success(response.message || "Comment approved successfully!");

        // Update local state to reflect approval
        setAllComments((prev) =>
          prev.map((comment) =>
            comment.commentId === commentId
              ? { ...comment, isApproved: true }
              : comment
          )
        );
      } else {
        toast.error(response.message || "Failed to approve comment");
      }
    } catch (error) {
      toast.error("An error occurred while approving the comment.");
    }
  };

  // Handle comment deletion
  const handleDeleteComment = async (commentId) => {
    try {
      const response = await deleteComment(commentId);
      if (response.status === 1) {
        toast.success(response.message || "Comment deleted successfully!");

        // Remove the comment from the local state
        setAllComments((prev) =>
          prev.filter((comment) => comment.commentId !== commentId)
        );
      } else {
        toast.error(response.message || "Failed to delete comment");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the comment.");
    }
  };

  // Show a spinner if data is still loading
  if (loading) {
    return (
      <div className="flex justify-center items-center h-[60vh]">
        {/* Replace with your own spinner or CSS */}
        <div className="spin-loader"></div>
      </div>
    );
  }

  return (
    <div
      className="overflow-y-auto max-h-[calc(100vh-165px)] scroll-bar relative"
      onScroll={handleScroll}
    >
      {/* Table Section */}
      <table className="min-w-full text-sm">
        <thead className="bg-[#EFF4FF] text-[#929EB6] border-b border-[#D1DFFC] sticky top-0">
          <tr>
            <th className="p-4 text-left font-medium">S.No</th>
            <th className="p-4 text-left font-medium">User Name</th>
            <th className="p-4 text-left font-medium">Comment</th>
            <th className="p-4 text-left font-medium">Blog Title</th>
            <th className="p-4 text-left font-medium">Blog Category</th>
            <th className="p-4 text-left font-medium">Created At</th>
            <th className="p-4 text-left font-medium">Status</th>
            <th className="p-4 text-left font-medium">Actions</th>
          </tr>
        </thead>
        <tbody>
          {visibleComments.map((comment, index) => {
            // Truncate logic
            const truncatedComment =
              comment.comment && comment.comment.length > MAX_LENGTH
                ? comment.comment.slice(0, MAX_LENGTH) + "..."
                : comment.comment;

            return (
              <tr
                key={comment.commentId ?? index}
                className={`${
                  index % 2 === 0 ? "bg-[#F4F7FF]" : "bg-[#EBF1FF]"
                } border-b border-[#DCE2EC]`}
              >
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {index + 1}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                  {comment.username}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs xl:w-[400px] lg:w-[350px]">
                  <div className="flex">
                    <span>{truncatedComment}</span>
                    {comment.comment && comment.comment.length > MAX_LENGTH && (
                      <button
                        onClick={() => handleOpenModal(comment)}
                        className="ml-2 text-blue-600 text-xs underline"
                      >
                        Read more
                      </button>
                    )}
                  </div>
                </td>
                <td className="p-4 text-[#193B68]">
                  {comment.title ? (
                    <p className="max-w-64 xl:text-sm lg:text-xs">
                      {comment.title}
                    </p>
                  ) : (
                    <>-</>
                  )}
                </td>
                <td className="p-4">
                  {comment.category ? (
                    <p className="inline-flex items-center rounded-full bg-purple-50 px-2 py-1 lg:text-[10px] xl:text-xs font-medium text-purple-700 ring-1 ring-blue-700/10 ring-inset">
                      {comment.category}
                    </p>
                  ) : (
                    <>-</>
                  )}
                </td>
                <td className="p-4 text-[#193B68] xl:text-sm lg:text-[11px]">
                  {formatDateDDMMMYYYY(comment.createdAt)}
                </td>
                <td className="p-4 text-[#193B68]">
                  {comment.isApproved ? (
                    <button
                      type="button"
                      className="px-3 py-1 text-xs bg-green-600 text-white rounded flex items-center cursor-default"
                      disabled
                    >
                      <PiCheckCircle className="text-base mr-2" />
                      Approved
                    </button>
                  ) : (
                    <button
                      onClick={() => handleApproveComment(comment.commentId)}
                      className="px-3 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      Approve
                    </button>
                  )}
                </td>
                <td className="p-4 ">
                  <button onClick={() => handleDeleteComment(comment.commentId)}>
                    <LuTrash2 className="text-lg text-[#193B68] hover:text-red-500" />
                  </button>
                </td>
              </tr>
            );
          })}
          {/* If there are no visible comments at all */}
          {visibleComments.length === 0 && !loading && (
            <tr>
              <td
                colSpan="8"
                className="py-5 px-4 bg-[#F4F7FF]/80 text-center text-gray-500"
              >
                No comments found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal Popup */}
      {selectedComment && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="bg-white p-6 rounded shadow-lg max-w-md w-11/12 md:w-1/2 relative">
            <p className="text-[#193B68] text-sm">{selectedComment.comment}</p>
            <div className="absolute top-[-10px] right-[-10px] bg-gray-100 flex justify-center rounded-full p-2">
              <button onClick={handleCloseModal}>
                <img
                  alt="close-icon"
                  className="w-5"
                  src="https://content.qeeb.in/coltec/close-icon.svg"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentDetails;
