import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
export const validateAdmin = async (userName, password) => {
  const apiUrl = `${baseUrl}/api/ColtecAdmin/ValidateAdmin?userName=${userName}&password=${password}`;
  try {
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    // Optionally, you can inspect error.response for more details
    throw error;
  }
};
