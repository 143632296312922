export const testimonials = [
  {
    name: "Ms. Aisha Rahman",
    institution: "Shaheen Group of Institutions, Karnataka",
    role: "Science Teacher",
    title:"Interactive lessons mad easy and fun",
    testimonial:
      "Our students love the paper-like writing surface—it feels just like writing on a notebook! We use the board to illustrate chemical reactions and label diagrams in real time. I’ve seen a marked improvement in test scores—up by nearly 10% in just one semester!",
    action: "Experience the difference yourself.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile.webp",
  },
  {
    name: "Mr. Rakesh Singh",
    institution: "Spring Field International Schools, Karnataka",
    role: "Social Studies Department Head",
    title:"A new era of classroom participation.",
    testimonial:
      "With the COLTEC Smart Interactive Board, students race to demonstrate map skills and historical timelines. I’ve never seen them so engaged—they’re more curious and ask deeper questions.",
    action: "Empower your students today.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile-2.webp",
  },
  {
    name: "Ms. Shalini Verma",
    institution: "Gaudium International School, Telangana",
    role: "English & Drama Teacher",
    title:"A perfect blend of clarity and collaboration.",
    testimonial:
      "From streaming Shakespeare plays in high-definition to annotating scripts, the 4K display and dual speakers have turned every lesson into an immersive experience. My students are more confident performing and analyzing texts.",
    action: "Turn up the engagement in your classroom.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile-1.webp",
  },
  {
    name: "Ms. Preeti Kulkarni",
    institution: "DAV Public School, Maharashtra",
    role: "Mathematics Teacher",
    title:"Reinforcing concepts with real-time feedback",
    testimonial:
      "I can quickly see which steps students struggle with by having them solve problems on the board. Their confidence has soared, and we’ve reduced post-class remedial sessions by almost half.",
    action: "Elevate your teaching methods.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile.webp",
  },
  {
    name: "Ms. Divya Saxena",
    institution: "Delhi Public School, Andhra Pradesh",
    role: "Computer Science Instructor",
    title:"Truly future-ready teaching tools.",
    testimonial:
      "The Android platform is a game-changer. We run coding tutorials, interactive quizzes, and video lessons all on the board—no external devices needed. Students love how responsive it is.",
    action: "Bring the future to your classroom.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile-1.webp",
  },
  {
    name: "Mr. Rahul Deshpande",
    institution: "Don Bosco High School, Maharashtra",
    role: "Art & Design Teacher",
    title:"Boosting attention spans and creativity",
    testimonial:
      "Being able to sketch digitally with the precision of pen on paper is amazing. My art students take more creative risks, and the class environment is positively electric.",
    action: "Inspire the artist in every student.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile-5.webp",
  },
  {
    name: "Ms. Anita Nair",
    institution: "Kendriya Vidyalaya, Kerala",
    role: "Primary Grade Coordinator",
    title:"Helping every child stay involved.",
    action: "Empower your youngest minds",
    testimonial:
      "Even our youngest learners confidently approach the board to trace letters and shapes. The toughened glass surface endures their enthusiastic tapping without a scratch.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile-1.webp",
  },
  {
    name: "Mr. Alex D’Souza",
    institution: "Loyola High School, Goa",
    role: "Vice Principal & Physics Teacher",
    title:"From lectures to lively dialogues.",
    testimonial: "I use it to visually explain physics experiments in 4K detail. Students now propose their own theories and experiments—class has become a two-way conversation.",
    action: "Transform your lectures into interactive discussions.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile-7.webp",
  },
  {
    name: "Ms. Roshni Das",
    institution: "St. Joseph High School, Odisha",
    role: "Biology Teacher",
    title:"Bringing clarity to complex topics.",
    testimonial: "Microscopic images and cell diagrams come to life in crystal-clear detail. Students used to struggle with those visuals, but now they’re genuinely fascinated.",
    action: "Make every lesson come alive.",
    qoute_img: "https://content.qeeb.in/coltec/landing/qoute-icon.svg",
    profile_img: "https://content.qeeb.in/coltec/landing/profiles/profile-8.webp",
  },
];
