import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
const emailRegistration = async (email, userName) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/Registration/EmailRegistration?email=${email}&userName=${userName}`
    );
    return response.data;
  } catch (error) {
    console.error("Error validating user", error);
    throw error;
  }
};

export default emailRegistration;
