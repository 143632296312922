import React, { useState } from "react";
import { products } from "../../utils/productsData";
import Footer from "../../components/Footer";
import ScrollToTopButton from "../../components/ScrollTop";
import Testimonials from "../../components/Testimonials";
import WhatsAppMsg from "../../components/WhatsApp";
import FAQ from "../../components/FAQ";
import { faqData } from "../../utils/productFAQ";
import Header from "../../components/Header";
import SEO from "../../components/SEO";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper/modules";

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    "Interactive Flat Panel"
  );

  // Handle category selection
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const testimonials = [
    {
      image:
        "https://content.qeeb.in/coltec/testimonials/global-sainik-academy.jpg",
      name: "Sharan Sikenpore",
      altImage: "Coltec Customer Global Sainik Academy",
      designation: "Founder Chairman & CEO",
      description:
        "“The interactive flat panels from COLTEC have transformed our classrooms! The crystal-clear display and responsive touch technology make lessons more engaging for students. We’re very happy with the performance and reliability.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/shaheen-group.jpg",
      name: "Mr Qursheed Ahmed",
      altImage: "Coltec Customer Shaheen Group",
      designation: "Academic Councellor",
      description:
        "“Implementing COLTEC’s interactive panels has enhanced our teaching experience. The easy-to-use interface and built-in educational tools have made learning more dynamic and interactive.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/gaudium-logo.png",
      name: "Sudeshna Chatterjee",
      altImage: "Coltec Customer Gaudium School",
      designation: "Head of School",
      description:
        "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/iit-madras-logo.png",
      name: "Vikas Sitraman",
      altImage: "Coltec Customer IIT Madras",
      designation: "IIT Madras - Academic Councellor",
      description:
        "“Our classrooms are more interactive and vibrant thanks to COLTEC’s IFPDs. The durability and advanced features like multi-touch and integrated content have made a significant impact on student engagement.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/berkadia.jpg",
      name: "Raj Kumar",
      altImage: "Coltec Customer Berkadia",
      designation: "AV Head",
      description:
        "“COLTEC’s interactive displays are a cornerstone of our new training facilities. The high-quality visuals and intuitive touch controls have enhanced our employee training programs significantly. We are very pleased with the results.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/spring-filed.jpg",
      name: "Akash Awasti",
      altImage: "Coltec Customer Spring Filed",
      designation: "Vice Principal",
      description:
        "“Integrating COLTEC’s interactive panels has truly modernized our classrooms. The high-definition display and robust interactive features have significantly boosted student engagement. Teachers find it intuitive to use, and students are more excited to participate in lessons. It’s been a fantastic investment for our school.”",
    },
    {
      image: "https://content.qeeb.in/coltec/testimonials/edify-education.png",
      name: "Kishore Pandit",
      altImage: "Coltec Customer Edify Education",
      designation: "Head of School",
      description:
        "“COLTEC’s interactive flat panels have transformed our teaching methods. The seamless integration of multimedia content and the responsive touch interface make lessons more interactive and enjoyable. Our teachers and students are thrilled with the new technology, which has made learning much more dynamic and effective.”",
    },
    {
      image:
        "https://content.qeeb.in/coltec/testimonials/the-dollar-business.png",
      name: "Ashok Tripathi",
      altImage: "Coltec Customer The Dollar Business",
      designation: "IT Head",
      description:
        "“We’re thrilled with the versatility of COLTEC’s smart panels. They’ve integrated perfectly into our boardrooms, providing a powerful tool for presentations and brainstorming sessions.”",
    },
  ];
  return (
    <>
      <div className="about-us-page pt-10">
        <SEO
          title="Discover Coltec’s Interactive Flat Panels & Solutions"
          description="Explore Coltec's range of interactive flat panels and collaboration solutions, designed to enhance engagement and productivity in both education and business environments."
          keywords="interactive flat panels,touchscreen displays,digital whiteboards,collaboration solutions,educational technology, corporate training displays, zero bonding touch technology,multi-touch panels,remote learning solutions,hybrid classrooms,digital signage,interactive display supplier in India,best interactive flat panel for schools,business collaboration tools,high-definition interactive displays,teaching software solutions,anti-glare interactive screens, smart classroom technology"
          ogTitle="Coltec Global | Innovative Interactive Displays & Collaboration Solutions"
          ogDescription="Discover the full range of Coltec interactive flat panels, designed to revolutionize presentations, learning, and collaboration for modern classrooms and workplaces."
          ogImage="https://content.qeeb.in/coltec/products/banner-product-three.webp"
          ogUrl="https://coltec-global.com/products"
          canonicalUrl="https://coltec-global.com/products"
          ogImageAlt="Coltec Global Product Lineup of Interactive Flat Panels"
          ogSiteName="Coltec Global"
          ogType="website"
        />

        <Header />
        <div className="px-5 pt-8">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="product-slider-list"
          >
            <SwiperSlide>
              <img
                src="https://content.qeeb.in/coltec/products/banner-product-three.webp"
                alt="Website Banner"
                className="w-full md:h-auto h-[160px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="https://content.qeeb.in/coltec/products/banner-product-one.webp"
                alt="Website Banner"
                className="w-full md:h-auto h-[160px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="https://content.qeeb.in/coltec/products/banner-product-two.webp"
                alt="Website Banner"
                className="w-full md:h-auto h-[160px]"
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
          <h1 className="md:text-[32px] text-[28px] leading-9 font-normal lg:text-center md:mb-10 mb-5">
            Shop by Categories
          </h1>
          <div className="">
            <div className="grid grid-cols-12 lg:gap-10 gap-4">
              {/* Left Sidebar: Categories */}
              <div className="md:col-span-3 col-span-full">
                <ul className="flex flex-wrap md:block md:space-y-4">
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "Interactive Flat Panel"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                    onClick={() =>
                      handleCategoryClick("Interactive Flat Panel")
                    }
                  >
                    Interactive Flat Panel
                  </li>
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "RoomScheduler"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                  >
                    Room Scheduler
                  </li>
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "Kiosk"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                  >
                    Kiosk
                  </li>
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "All-in-One Touch Monitor"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                  >
                    All-in-One Touch Monitor
                  </li>
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "Tablet"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                  >
                    Tablet
                  </li>
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "Video Wall"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                  >
                    Video Wall
                  </li>
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "Commercial Display"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                  >
                    Commercial Display
                  </li>
                  <li
                    className={`cursor-pointer py-2 px-3 lg:text-base text-xs mb-3 mr-3 md:mb-0 md:mr-0 ${
                      selectedCategory === "Active LED"
                        ? "lg:w-[234px] lg:h-11 bg-white rounded-lg shadow-[0px_4px_12px_0px_rgba(13,13,13,0.15)] border-2 border-[#4282ff]"
                        : "lg:w-[234px] lg:h-11 bg-[#ececec] rounded-lg"
                    }`}
                  >
                    Active LED
                  </li>
                  {/* Add more categories similarly... */}
                </ul>
              </div>

              {/* Right Side: Products */}
              <div className="md:col-span-9 col-span-full">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6">
                  {products[selectedCategory].map((product) => (
                    <div key={product.id}>
                      <Link
                        to={`/product-details/${product.slug}`}
                        target="_blank"
                      >
                        <div className="flex-shrink-0 bg-white rounded-lg p-4 border shadow-md overflow-hidden">
                          <img
                            src={product.imageUrl}
                            alt={product.name}
                            className="rounded-lg hover:scale-105 transition-all duration-500 object-contain"
                          />
                        </div>
                        <div className="mt-5">
                          <h3 className="text-lg font-medium">
                            {product.name}
                          </h3>
                          <p className="text-base text-gray-800">
                            ₹{product.price}{" "}
                            <span className="line-through text-gray-400">
                              ₹{product.originalPrice}
                            </span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#f5f5f5]">
          <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative">
            <Testimonials testimonials={testimonials} />
          </div>
        </div>
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
          <FAQ data={faqData} />
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default Products;
