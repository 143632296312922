import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";

function SafetyComfort() {
  return (
    <>
      <div className="px-5 lg:px-8 lg:py-10 pb-10 max-w-6xl m-auto">
        <div className="max-w-5xl md:text-center mx-auto mb-10">
          <span class="text-black md:text-[30px] text-2xl font-semibold md:leading-[48px]">
            Engineered with Advanced Tech and Durability, COLTEC Smart
            Interactive Flat Panels Ensures{" "}
          </span>
          <span class="text-[#79adf3] md:text-[30px] text-2xl font-semibold  md:leading-[48px]">
            Safety and Comfort
          </span>
        </div>
        <Swiper
        spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper dynamic-slider"
        >
          <SwiperSlide>
            <div className="md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="col-span-1 mb-6 md:mb-0 row-span-1 lg:h-[270px] bg-[#538cff] rounded-3xl">
                <div className="p-6">
                  <img
                    alt=" Fire-Retardant Materials"
                    src="https://content.qeeb.in/coltec/landing/fire-retardant-materials.webp"
                    className="mb-3 h-[125px] w-full object-contain"
                  />
                  <h2 className="text-white text-lg font-semibold mb-2">
                    Fire-Retardant Materials
                  </h2>
                  <p className="w-[298px] text-white text-xs font-medium">
                    Constructed with high-quality, fire-resistant materials to
                    meet stringent classroom safety standards.
                  </p>
                </div>
              </div>
              <div className="col-span-1 row-span-1 mb-6 md:mb-0 lg:h-[330px] bg-[#538cff] rounded-3xl">
                <div className="">
                  <div className="px-6 pt-6">
                    <h2 className="text-white text-lg font-semibold  mb-3">
                      Rounded Edges
                    </h2>
                    <p className="w-[298px] text-white text-xs font-medium  ">
                      Panels are designed with smooth, rounded corners to
                      eliminate sharp edges, enhancing physical safety in active
                      learning spaces.
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <img
                      alt="Rounded Edges"
                      src="https://content.qeeb.in/coltec/landing/rounded-edges.webp"
                      className="w-[180px] h-[218px] object-fill"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 row-span-1 mb-6 md:mb-0 lg:h-[330px] bg-white rounded-3xl border-4 border-[#538cff]">
                <div className="p-6">
                  <img
                    alt="Durable Toughened Glass"
                    src="https://content.qeeb.in/coltec/landing/durable-toughened-glass.webp"
                    className="mb-3 h-[150px] w-full object-contain"
                  />
                  <h2 className="text-[#4282ff] text-lg font-semibold  mb-3">
                    Durable Toughened Glass
                  </h2>
                  <p className="w-[254px] text-black text-xs font-medium">
                    Built with scratch-resistant, impact-proof toughened glass,
                    these devices are designed to withstand daily classroom use
                    and accidental impacts.
                  </p>
                </div>
              </div>
              <div className="col-span-1 row-span-1 mb-6 md:mb-0 lg:h-[300px] xl:mt-[-55px] bg-white rounded-3xl border-4 border-[#538cff]">
                <div className="p-6">
                  <img
                    alt="Secure Mounting Options"
                    src="https://content.qeeb.in/coltec/landing/secure-mounting-options.webp"
                    className="mb-3 h-[150px] w-full object-contain"
                  />
                  <h2 className="text-[#4282ff] text-lg font-semibold mb-1">
                    Secure Mounting Options
                  </h2>
                  <div className="w-[254px] text-black text-xs font-medium  ">
                    Sturdy wall mounts and mobile stands designed to prevent
                    tipping or accidental falls, ensuring safe installations.
                  </div>
                </div>
              </div>
              <div className="col-span-2 row-span-1 bg-[#538cff] rounded-3xl">
                <div className="p-6">
                  <div className="flex justify-start lg:space-x-5 flex-wrap">
                    <img
                      alt="Anti-Glare Technology"
                      src="https://content.qeeb.in/coltec/landing/anti-glare-technology.webp"
                      className="h-[160px]"
                    />
                    <div>
                      <h2 className="text-white text-lg font-semibold ">
                        Anti-Glare Technology
                      </h2>
                      <p className="w-[350px] text-white text-xs font-medium  ">
                        Integrated anti-glare interactive screen reduces
                        reflections, providing clear visuals while safeguarding
                        students’ eyes in brightly lit classrooms, ensuring a
                        comfortable and immersive learning experience without
                        distractions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="col-span-1 row-span-1  mb-6 md:mb-0 lg:h-[270px] bg-white rounded-3xl border-4 border-[#538cff]">
                <div className="p-6">
                  <img
                    alt="Adjustable Brightness"
                    src="https://content.qeeb.in/coltec/landing/adjustable-brightness.webp"
                    className="mb-1 h-[125px] w-full object-contain"
                  />
                  <h2 className="text-[#4282ff] text-lg font-semibold mb-2">
                    Adjustable Brightness
                  </h2>
                  <p className="w-[298px] text-black text-xs font-medium">
                    Smart Interactive Boards from COLTEC allow teachers to
                    adjust brightness levels, reducing eye strain during long
                    lessons.
                  </p>
                </div>
              </div>
              <div className="col-span-1 row-span-1  mb-6 md:mb-0 lg:h-[330px] bg-white rounded-3xl border-4 border-[#538cff]">
                <div className="p-6">
                  <div>
                    <h2 className="text-[#4282ff] text-lg font-semibold mb-2">
                      Flicker-Free Display
                    </h2>
                    <p className="w-[298px] text-black text-xs font-medium">
                      Advanced screen technology eliminates flickering,
                      delivering a stable and comfortable viewing experience for
                      students.
                    </p>
                  </div>
                  <div className="flex justify-end pt-[25px]">
                    <img
                      alt="Flicker-Free Display"
                      src="https://content.qeeb.in/coltec/landing/flicker-free-display.webp"
                      className="h-[160px] w-full object-contain"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 row-span-1  mb-6 md:mb-0 lg:h-[330px] bg-[#538cff] rounded-3xl border-4 border-[#538cff]">
                <div className="p-6">
                  <img
                    alt="Ergonomic Content Display"
                    src="https://content.qeeb.in/coltec/landing/ergonomic-content-display.webp"
                    className="mb-3 h-[150px] w-full object-contain"
                  />
                  <h2 className="text-white text-lg font-semibold mb-2">
                    Ergonomic Content Display
                  </h2>
                  <p className="w-[290px] text-white text-xs font-medium">
                    The ergonomically designed interactive display delivers
                    clear visuals, ensuring easy readability of text, images,
                    and videos while creating a comfortable, immersive, and
                    engaging learning experience for all students
                  </p>
                </div>
              </div>
              <div className="col-span-1 row-span-1  mb-6 md:mb-0 lg:h-[300px] xl:mt-[-55px] bg-[#538cff] rounded-3xl border-4 border-[#538cff]">
                <div className=" p-6">
                  <img
                    alt="Blue Light Filter"
                    src="https://content.qeeb.in/coltec/landing/blue-light-filter.webp"
                    className="mb-3 h-[150px] w-full object-contain"
                  />
                  <h2 className="text-white text-lg font-semibold mb-2">
                    Blue Light Filter
                  </h2>
                  <div className="w-[290px] text-white text-xs font-medium">
                    Protects students’ eyes with advanced blue light reduction
                    technology, minimizing the risks of prolonged screen
                    exposure.
                  </div>
                </div>
              </div>
              <div className="col-span-2 row-span-1 border-4 border-[#538cff] bg-white rounded-3xl ">
                <div className="p-6 ">
                  <div className="flex justify-start items-center flex-wrap lg:space-x-8">
                    <img
                      alt="Optimal Viewing Angles"
                      src="https://content.qeeb.in/coltec/landing/optimal-viewing-angles.webp"
                      className="h-[160px] mt-3"
                    />
                    <div>
                      <h2 className="text-[#538cff] text-lg font-semibold mb-2">
                        Optimal Viewing Angles
                      </h2>
                      <p className="w-[298px] text-black text-xs font-medium">
                        Wide-angle interactive display ensures that every
                        student, regardless of seating position, can clearly
                        view the content on the screen with optimal clarity and
                        engagement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

export default SafetyComfort;
