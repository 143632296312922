export const slides = [
  {
    steps: [
      {
        title: "High Focus",
        description:
          "With clear visuals and interactive tools, students remain focused on the task at hand, minimizing distractions and improving concentration during lessons.",
        image:
          "https://content.qeeb.in/coltec/landing/features/cutting-edge-interactive-flat-panel.webp",
        icon: "https://content.qeeb.in/coltec/landing/features/high-focus.webp",
        alt: "Smart interactive flat panel redefining teaching with cutting-edge technology.",
      },
      {
        title: "Engagement",
        description:
          "Touchscreen functionality, interactive quizzes, and multimedia elements actively involve students, making lessons dynamic and participatory.",
        image:
          "https://content.qeeb.in/coltec/landing/features/teacher-student-engagement-flat-panel.webp",
        icon: "https://content.qeeb.in/coltec/landing/features/engagement.webp",
        alt: "Interactive flat panel boosting student-teacher engagement in smart classrooms.",
      },
      {
        title: "Increased Interest",
        description:
          "Vibrant 4K displays and engaging content like videos, animations, and 3D models spark curiosity, making learning more enjoyable and memorable.",
        image:
          "https://content.qeeb.in/coltec/landing/features/hands-on-learning-touch-screen.webp",
        icon: "https://content.qeeb.in/coltec/landing/features/increased-interest.webp",
        alt: "Interactive touch screen for collaborative and hands-on classroom activities.",
      },
    ],
  },
  {
    steps: [
      {
        title: "Everyone on the Same Page",
        description:
          "Real-time annotations, screen sharing, and collaborative tools ensure that all students, whether in-class or remote, are aligned and can follow lessons seamlessly.",
        image:
          "https://content.qeeb.in/coltec/landing/features/dynamic-interactive-screen-classroom.webp",
        icon: "https://content.qeeb.in/coltec/landing/features/everyone-same-page.webp",
        alt: "Interactive screen supporting dynamic and creative learning in classrooms.",
      },
      {
        title: "Improved Attention",
        description:
          "Interactive features and multimedia break the monotony of traditional teaching methods, helping capture and sustain student attention throughout the session.",
        image:
          "https://content.qeeb.in/coltec/landing/features/touch-enabled-interactive-panel.webp",
        icon: "https://content.qeeb.in/coltec/landing/features/improved-attention.webp",
        alt: "Touch-enabled interactive touch panel for real-time classroom interaction.",
      },
      {
        title: "Better Retention",
        description:
          "Visual aids, hands-on interaction, and engaging teaching methods enhance understanding, allowing students to retain information longer and apply it effectively.",
        image:
          "https://content.qeeb.in/coltec/landing/features/seamless-teaching-smart-panel.webp",
        icon: "https://content.qeeb.in/coltec/landing/features/better-retention.webp",
        alt: "Smart interactive flat panel offering seamless teaching and engagement.",
      },
    ],
  },
];
