// Optionally, you can keep this if you need a random index elsewhere
export const getRandomImageIndex = (images) => {
  return Math.floor(Math.random() * images.length);
};

export const startImageRotation = (images, setCurrentImageIndex, intervalTime = 3000) => {
  const intervalId = setInterval(() => {
    // Increase the index by 1 and use modulo to wrap around to 0 after the last image.
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, intervalTime);

  return () => clearInterval(intervalId); // Cleanup function to clear the interval
};
