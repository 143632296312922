import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
const validateUser = async (email, password) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/Authentication/ValidateUser?UserName=${email}&Password=${password}`
    );
    return response.data;
  } catch (error) {
    console.error("Error validating user", error);
    throw error;
  }
};

export default validateUser;
