import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
// Create a new discount coupon
export const createDiscountCoupon = async (discount, description) => {
  try {
    const response = await axios.get(
      `${baseUrl}/api/ColtecAdmin/CreateDiscountcoupon?Discount=${discount}&Description=${description}`
    );
    return response.data;
  } catch (error) {
    console.error("Error creating coupon:", error);
    throw error;
  }
};
