import React, { useEffect, useState } from "react";
import { fetchUserDetails } from "../utils/adminUserData";
import { useAuth } from "../utils/adminContext";
// Import the helper functions
import { formatDateDDMMMYYYY, timeAgo } from "../utils/dateHelpers";

const AdminUserDetails = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  
  // We'll store the sorted version of filteredUsers separately
  const [sortedUsers, setSortedUsers] = useState([]);
  
  // For infinite scroll, we'll keep track of how many records are currently visible
  const [visibleCount, setVisibleCount] = useState(25);
  const [visibleUsers, setVisibleUsers] = useState([]);

  const [usernameFilter, setUsernameFilter] = useState("");
  const [addressFilter, setAddressFilter] = useState("");

  const [loading, setLoading] = useState(false); // to show spinner while fetching
  
  const { isLoggedIn, openSignInModal } = useAuth();

  // 1) If the user is not logged in, open the sign-in modal
  useEffect(() => {
    if (!isLoggedIn) {
      openSignInModal();
    }
  }, [isLoggedIn, openSignInModal]);

  // 2) Fetch data ONLY if logged in
  useEffect(() => {
    const getUsers = async () => {
      if (isLoggedIn) {
        setLoading(true);
        try {
          const userData = await fetchUserDetails();
          setUsers(userData);
          setFilteredUsers(userData); // Initialize filteredUsers
        } catch (error) {
          console.error("Error fetching users:", error);
        } finally {
          setLoading(false);
        }
      } else {
        // Clear if not logged in
        setUsers([]);
        setFilteredUsers([]);
      }
    };
    getUsers();
  }, [isLoggedIn]);

  // 3) Filter logic
  useEffect(() => {
    const filtered = users.filter((user) => {
      const matchesUsername =
        !usernameFilter || user.userName === usernameFilter;
      const matchesAddress =
        !addressFilter || (user.address && user.address === addressFilter);

      return matchesUsername && matchesAddress;
    });
    setFilteredUsers(filtered);
    // Whenever the filters change, reset visibleCount to 25 (start from first chunk)
    setVisibleCount(25);
  }, [usernameFilter, addressFilter, users]);

  // 4) Sort filtered users (latest on top based on `lastLoginDate`)  
  //    Then slice according to `visibleCount`.
  useEffect(() => {
    // Sort descending by lastLoginDate
    const sorted = [...filteredUsers].sort(
      (a, b) => new Date(b.lastLoginDate) - new Date(a.lastLoginDate)
    );
    setSortedUsers(sorted);
  }, [filteredUsers]);

  // 5) Update the visible users whenever `sortedUsers` or `visibleCount` changes
  useEffect(() => {
    setVisibleUsers(sortedUsers.slice(0, visibleCount));
  }, [sortedUsers, visibleCount]);

  // 6) If not logged in, render nothing (or a placeholder)
  if (!isLoggedIn) {
    return null;
  }

  // 7) Handle infinite scroll
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // If scrolled to bottom within a small threshold (e.g., 5px offset)
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      // Increase visibleCount by 25 if we still have more records to show
      if (visibleCount < sortedUsers.length) {
        setVisibleCount((prev) => prev + 25);
      }
    }
  };

  // 8) If data is still loading, show a spinner
  if (loading) {
    return (
      <div className="flex justify-center items-center h-[60vh]">
        <div className="spin-loader"></div>
      </div>
    );
  }

  // 9) Prepare dropdown options
  const usernameOptions = Array.from(new Set(users.map((u) => u.userName)));
  const addressOptions = Array.from(
    new Set(users.map((u) => u.address).filter(Boolean))
  );

  // 10) Render
  return (
    <>
      <div className="flex space-x-4 px-8 mb-6">
        {/* Username dropdown */}
        <select
          value={usernameFilter}
          onChange={(e) => setUsernameFilter(e.target.value)}
          className="w-[240px] border text-[15px] flex justify-center items-center px-3 border-gray-200  py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">Filter by users</option>
          {usernameOptions.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>

        {/* Address dropdown */}
        <select
          value={addressFilter}
          onChange={(e) => setAddressFilter(e.target.value)}
          className="w-[200px] border text-[15px] flex justify-center items-center px-3 border-gray-200  py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">Filter by location</option>
          {addressOptions.map((addr) => (
            <option key={addr} value={addr}>
              {addr}
            </option>
          ))}
        </select>
      </div>

      {/* Table container with onScroll */}
      <div
        className="overflow-y-auto max-h-[calc(100vh-231px)] scroll-bar"
        onScroll={handleScroll}
      >
        <table className="min-w-full text-sm">
          <thead className="bg-[#EFF4FF] text-[#929EB6] border-b border-[#D1DFFC] sticky top-0">
            <tr>
              <th className="p-4 text-left font-medium">S.No</th>
              <th className="p-4 text-left font-medium">User Name</th>
              <th className="p-4 text-left font-medium">Phone No</th>
              <th className="p-4 text-left font-medium">Email</th>
              <th className="p-4 text-left font-medium">Organization</th>
              <th className="p-4 text-left font-medium">Location</th>
              <th className="p-4 text-left font-medium">Last Login</th>
              <th className="p-4 text-left font-medium">No of Login</th>
              <th className="p-4 text-left font-medium">Member Since</th>
            </tr>
          </thead>
          <tbody>
            {visibleUsers.length > 0 ? (
              visibleUsers.map((user, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-[#F4F7FF]" : "bg-[#EBF1FF]"
                  } border-b border-[#DCE2EC]`}
                >
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {index + 1}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {user.userName}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {user.phoneNo || "-"}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {user.email}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {user.organizationName || "-"}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {user.address || "-"}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {formatDateDDMMMYYYY(user.lastLoginDate) || "-"}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {user.loginCount || "-"}
                  </td>
                  <td className="p-4 text-[#193B68] xl:text-sm lg:text-xs">
                    {timeAgo(user.registerDate) || "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="10"
                  className="py-5 px-4 bg-[#F4F7FF]/80  text-center text-gray-500"
                >
                  No users found matching the filters.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminUserDetails;
