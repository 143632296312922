export const featureData = [
  {
    id: 1,
    title: "Multi-Coloured Pens",
    description:
      "Seamlessly connect multiple devices for efficient collaboration.",
    span: "lg:col-span-2 lg:row-span-1 bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-b from-[#fbfcff] to-[#dbe8ff]",
    icon: "https://content.qeeb.in/coltec/landing/multi-coloured-pens.gif",
    flex_class: "flex lg:flex-nowrap flex-wrap space-x-5",
  },
  {
    id: 2,
    title: "Multi Backgrounds",
    description:
      "Seamlessly connect multiple devices for efficient collaboration.",
    span: "lg:col-span-2 lg:row-span-1 bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-br from-[#fbfcff] to-[#dcdbff]",
    icon: "https://content.qeeb.in/coltec/landing/multi-backgrounds.gif",
    flex_class: "flex lg:flex-nowrap flex-wrap space-x-5",
  },
  {
    id: 3,
    title: "Text & Palm Eraser",
    description:
      "Seamlessly connect multiple devices for efficient collaboration.",
    span: "lg:col-span-1 lg:row-span-2 bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-b from-[#fbfcff] to-[#f9e8ff]",
    icon: "https://content.qeeb.in/coltec/landing/text-palm-eraser.gif",
  },
  {
    id: 4,
    title: "Smart Shapes",
    description: "Perfect shapes, drawn smarter, not harder!",
    span: "lg:col-span-2 lg:row-span-1 bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-b from-[#fbfffe] to-[#d4fff1]",
    icon: "https://content.qeeb.in/coltec/landing/smart-shapes.gif",
    flex_class: "flex lg:flex-nowrap flex-wrap space-x-5",
  },
  {
    id: 5,
    title: "Multi Touch Points",
    description: "Collaborate seamlessly with multi-touch technology.",
    span: "lg:col-span-2 lg:row-span-1 bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-b from-[#fffdfb] to-[#fcfde7]",
    icon: "https://content.qeeb.in/coltec/landing/multi-touch-points.gif",
    flex_class: "flex lg:flex-nowrap flex-wrap space-x-5",
  },
  {
    id: 6,
    title: "Insert Table, Mind Maps, Characters & Shapes",
    description: "Add structure to your creativity with versatile tools.",
    span: "lg:col-span-1 lg:row-span-1 lg:mt-[-230px] bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-b from-[#fbffff] to-[#dbf9ff]",
    icon: "https://content.qeeb.in/coltec/landing/insert-table-shapes-mind-maps.gif",
  },
  {
    id: 7,
    title: "Pattern Recognition",
    description:
      "Seamlessly connect multiple devices for efficient collaboration.",
    span: "lg:col-span-2 lg:row-span-1 bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-b from-[#fbfcff] to-[#ffe2db]",
    icon: "https://content.qeeb.in/coltec/landing/pattern-recognition.gif",
    flex_class: "flex lg:flex-nowrap flex-wrap space-x-5",
  },
  {
    id: 8,
    title: "Smart Pen",
    description: "Turn your handwriting into stunning visuals instantly!",
    icon: "https://content.qeeb.in/coltec/landing/smart-pen.gif",
    span: "lg:col-span-2 lg:row-span-1 bg-[#f1f6ff] rounded-2xl border border-neutral-300 bg-gradient-to-b from-[#fbfcff] to-[#dbfffc]",
    flex_class: "flex lg:flex-nowrap flex-wrap space-x-5",
  },
];
