import React from "react";
import Header from "../components/Header";
import SEO from "../components/SEO";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollTop";
import WhatsAppMsg from "../components/WhatsApp";

const EWastePolicy = () => {
  return (
    <>
      <div className="about-us-page">
        <SEO
          title="Refund Return Policy | Coltec Global"
          description="Learn how Coltec Global collects, uses, and protects your personal data in compliance with GDPR."
          keywords="Refund Return Policy, GDPR Compliance, Data Protection, Coltec Global"
          ogTitle="Refund Return Policy | Coltec Global"
          ogDescription="Understand Coltec Global's approach to data privacy and protection."
          ogUrl="https://coltec-global.com/refund-return-policy"
          ogType="website"
        />
        <Header />
        <div className="max-w-7xl px-5 lg:px-8 m-auto mx-auto mt-24 pb-12">
          <div className="p-8 bg-white shadow-lg border-pink-200 border rounded-2xl text-gray-800">
            <h1 className="text-xl font-bold text-center mb-6">
              E-Waste Policy
            </h1>

            {/* E-waste Management Section */}
            <section>
              <h2 className="text-base font-semibold mb-4">
                E-Waste Management
              </h2>

              {/* Introduction */}
              <div className="mb-6">
                <h3 className="text-base font-semibold mb-2">Introduction</h3>
                <p className="mb-4 text-[15px]">
                  E-waste is defined as any discarded electrical or electronic
                  equipment. This comprises products that are either thrown away
                  or donated to a charity reseller such as Goodwill. When an
                  item in a store goes unsold, it is frequently discarded. Toxic
                  compounds naturally leach from the metals inside e-waste when
                  it is buried, making it extremely harmful.
                </p>
                <p className="mb-4 text-[15px]">
                  As per E-waste Rule 2016, the E-waste defined as ‘electrical
                  and electronic equipment, whole or in part discarded as waste
                  by the consumer or bulk consumer as well as rejects from
                  manufacturing, refurbishment and repair processes.
                </p>
                <p className="mb-4 text-[15px]">
                  Many valuable, recoverable elements can be found in e-waste,
                  including aluminum, copper, gold, silver, polymers, and
                  ferrous metals. In order to conserve natural resources and the
                  energy needed to produce new electronic equipment from virgin
                  resources, electronic equipment can be refurbished, reused,
                  and recycled instead of being land-filled.
                </p>
                <p className="mb-4 text-[15px]">
                  Mercury, lead, cadmium, beryllium, chromium, and chemical
                  flame retardants are among the toxic and hazardous compounds
                  found in e-waste, and they have the potential to leak into our
                  land and water.
                </p>
              </div>

              {/* Benefits of E-waste Recycling */}
              <div className="mb-6">
                <h3 className="text-base font-semibold mb-2">
                  Benefits of E-waste Recycling
                </h3>
                <ul className="list-disc list-inside space-y-4 text-[15px]">
                  <li>
                    <span className="font-medium">
                      Electronics Recycling Conserves Natural Resources:
                    </span>{" "}
                    There are numerous materials that can be recovered from old
                    electronics. These materials can be used to make new
                    products, thus reducing the need to mine for new raw
                    materials. For instance, various metals can be recovered
                    from computer circuit boards and other electronics, and the
                    plastics and glass found in computer monitors and
                    televisions can be recycled.
                  </li>
                  <li>
                    <span className="font-medium">
                      Electronics Recycling Supports the Community:
                    </span>{" "}
                    Donating your old electronics plays a major role in the
                    provision of refurbished products such as computers and
                    mobile phones, which can be of great help to low-income
                    families, schools, and not-for-profit organizations. It also
                    assists individuals in gaining access to technologies that
                    they otherwise would not have been able to buy.
                  </li>
                  <li>
                    <span className="font-medium">
                      Electronics Recycling Creates Employment Locally:
                    </span>{" "}
                    Considering that around 90 percent of electronic equipment
                    is recyclable, electronics recycling can play an important
                    role in generating employment. This is because new firms
                    dealing with electronics recycling will form and existing
                    firms will look to employ more people to recover recyclable
                    materials. This can be triggered by the increase in the
                    demand for electronics recycling.
                  </li>
                  <li>
                    <span className="font-medium">
                      Electronics Recycling Helps Protect Public Health and the
                      Environment:
                    </span>{" "}
                    Many electrical devices contain toxic or dangerous compounds
                    like mercury and lead, which can harm the environment if
                    discarded in trashcans. Reusing and recycling electronics in
                    a responsible manner helps to keep hazardous materials out
                    of the hands of humans and the environment. For example,
                    televisions and computer monitors are hazardous since they
                    have lead in them. Printed circuit boards contain harmful
                    materials such as cadmium, lead, mercury, and chromium.
                    Also, batteries in computers and other electronics may
                    contain hazardous materials such as cadmium, mercury, and
                    lead.
                  </li>
                  <li>
                    <span className="font-medium">Creates Jobs:</span> E-waste recycling creates new
                    jobs for professional recyclers and creates a second market
                    for the recycled materials.
                  </li>
                </ul>
              </div>

              {/* Collection Mechanism of E-waste */}
              <div className="mb-6">
                <h3 className="text-base font-semibold mb-2">
                  Collection Mechanism of E-waste
                </h3>

                {/* Buy-back Scheme */}
                <div className="mb-4 text-[15px]">
                  <h4 className="text-base font-semibold mb-2">
                    Buy-back Scheme
                  </h4>
                  <p className="mb-2 text-[15px]">
                    Under Buy-back Scheme, in cases where the customer wants to
                    discard their old product, they may call the Toll-Free
                    number (1800-102-5679) and our representatives shall
                    approach the customer and buy the said discarded product
                    from the customer in consideration.
                  </p>
                  <p className="mb-2 text-[15px]">
                    The Customer shall also be free to deposit the discarded
                    product at the nearest collection point and he will get a
                    discount voucher and shall be eligible for the discount on
                    the purchase of selected new products of the Company. The
                    company will coordinate with other independent vendors who
                    may sell the e-waste/EOL product collected by them.
                  </p>
                  <p className="mb-2 text-[15px]">
                    The company will furthermore elaborate its scheme and shall
                    keep updating the same based on the experience faced while
                    implementing the said schemes on a quarterly basis.
                  </p>
                </div>

                {/* Direct Collection from Customer’s premises */}
                <div className="mb-4 text-[15px]">
                  <h4 className="text-base font-semibold mb-2">
                    Direct Collection from Customer’s Premises
                  </h4>
                  <p className="mb-2 text-[15px]">
                    For collection of e-waste, the Company has set up a
                    toll-free number where any customer may call and register
                    itself for either depositing its old product free of cost or
                    otherwise under the buy-back program initiated by the
                    company. The company shall give its toll-free number on
                    their product manuals, website, social networking platforms,
                    and at its various connected premises for its customer
                    information for disposal of the e-waste. The customer can
                    directly call these numbers, and the e-waste will be
                    directly collected by the company or by its recycler
                    partner. The company has a recycler partner who manages
                    logistic services through OM Logistics Limited, available at
                    most of the locations at its collection centers.
                  </p>
                </div>
              </div>

              {/* Recycling of E-waste */}
              <div className="mb-6">
                <h3 className="text-base font-semibold mb-2">
                  Recycling of E-waste
                </h3>
                <p className="mb-2 text-[15px]">
                  The company has tied up with E-Waste Recyclers India (EWRI)
                  for the collection of all our e-waste PAN India and disposes
                  of the same at their plant at Facility – Industrial Area Shed
                  No-15, RozkaMeo Industrial Area, Nuh, Mewat, Haryana-122103.
                  Customers can reach or call the Toll-Free No 1800-102-5679.
                  Our representatives explain to them the process of disposal
                  and make them aware of the nearest drop point available to
                  drop the e-waste. We also provide information about the
                  incentives we will offer against their end-of-life product. If
                  any customer wants to handover the material from their
                  doorstep, we send either our logistics team or the E-waste
                  Solutions team to collect the items and channelize the same to
                  our e-waste partner plant for final processing.
                </p>
              </div>

              {/* Do’s & Don’ts */}
              <div>
                <h3 className="text-base font-semibold mb-2">
                  Do’s &amp; Don’ts
                </h3>

                {/* Do’s */}
                <div className="mb-4 text-[15px]">
                  <h4 className="text-base font-semibold mb-2">Do’s:</h4>
                  <ul className="list-disc list-inside space-y-2 text-[15px]">
                    <li>
                      Always look for information on the catalogue with your
                      product for end-of-life equipment handling.
                    </li>
                    <li>
                      Ensure that only Authorized Recyclers/Dismantlers handle
                      your electronic (i.e., LED TVs and accessories) products.
                    </li>
                    <li>
                      Always call our toll-free numbers to dispose of products
                      that have reached end-of-life.
                    </li>
                    <li>
                      Always drop your used electronic products, batteries, or
                      any accessories when they reach the end of their life at
                      your nearest Authorized E-Waste Collection Points.
                    </li>
                    <li>
                      Always disconnect the battery from the product, and ensure
                      any glass surface is protected against breakage.
                    </li>
                  </ul>
                </div>

                {/* Don’ts */}
                <div>
                  <h4 className="text-base font-semibold mb-2">Don’ts:</h4>
                  <ul className="list-disc list-inside space-y-2 text-[15px]">
                    <li>
                      Do not dismantle your electronic products on your own.
                    </li>
                    <li>
                      Do not throw electronics in bins having “Do not Dispose”
                      signs.
                    </li>
                    <li>
                      Do not give e-waste to informal and unorganized sectors
                      like Local Scrap Dealers/Rag Pickers.
                    </li>
                    <li>
                      Do not dispose of your product in garbage bins along with
                      municipal waste that ultimately reaches landfills.
                    </li>
                  </ul>
                  <img src="https://content.qeeb.in/coltec/e-waste-1.png" className="my-6" alt="EWaste-Image"/>
                  <img src="https://content.qeeb.in/coltec/e-waste-2.png" alt="EWaste-Image"/>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
        <ScrollToTopButton />
        <WhatsAppMsg />
      </div>
    </>
  );
};

export default EWastePolicy;
