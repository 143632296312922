export const sendQuotation = async (
  email,
  productIds,
  discountCoupon = "",
  totalPrice
) => {
  // Get base URL from .env file
  const baseUrl = process.env.REACT_APP_API_URL;

  // Ensure productIds is a comma-separated string
  const productIdsString = Array.isArray(productIds)
    ? productIds.join(",")
    : productIds;

  // Build the API URL using the new endpoint and query parameters
  const apiUrl = `${baseUrl}/api/Coltec/GenerateQuotation?Email=${encodeURIComponent(
    email
  )}&ProductIds=${encodeURIComponent(
    productIdsString
  )}&TotalPrice=${totalPrice}&DiscountCoupon=${encodeURIComponent(
    discountCoupon
  )}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to generate quotation");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
