import React, { useState } from "react";

const FAQ = ({ data }) => {
  // Track which FAQ index is open (or null if none)
  const [openIndex, setOpenIndex] = useState(null);

 // Function to detect if a string contains HTML
 const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

  // Toggle function
  const handleToggle = (index) => {
    // If the clicked item is already open, close it by setting openIndex to null
    // Otherwise, set openIndex to the clicked item
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <>
      <div className="w-full">
        <h2 className="md:text-[40px] text-[28px] text-center font-normal mb-8 text-gray-900">
          Frequently Asked Questions (FAQs)
        </h2>
        <div className="divide-y divide-gray-300 border-t border-b border-gray-300">
          {data.map((item, index) => {
            const isOpen = openIndex === index;

            return (
              <div key={index}>
                {/* Question row */}
                <button
                  type="button"
                  onClick={() => handleToggle(index)}
                  className={`transition-colors w-full flex items-center justify-between py-4 md:px-5 text-left ${
                    isOpen ? "bg-[#e2eafe]" : "bg-white"
                  }`}
                >
                  <span className="font-medium md:text-base text-sm text-gray-800">
                    {item.question}
                  </span>

                  {/* Icon (rotates when open) */}
                  <svg
                    className={`h-5 w-5 text-blue-500 transform transition-transform ${
                      isOpen ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {/* Answer section (conditional rendering) */}
                {isOpen && (
                  <div className="p-5 bg-[#f9f9f9] rounded-md">
                    <div className="flex flex-col md:flex-row items-start gap-5">
                     {/* Answer Section */}
                    <div className="text-[#767982] text-sm flex-1">
                      {isHTML(item.answer) ? (
                        // If HTML content, render as HTML
                        <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                      ) : (
                        // Otherwise, treat as plain text with line breaks
                        <p className="whitespace-pre-line">{item.answer}</p>
                      )}
                    </div>

                      {/* Image (if available) */}
                      {item.imageUrl && (
                        <div className="w-full md:w-1/3">
                          <img
                            src={item.imageUrl}
                            alt={item.question}
                            className="w-full rounded-md shadow-md"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FAQ;
