export const features = [
  {
    id: 1,
    icon: "https://content.qeeb.in/coltec/landing/multi-source-connectivity.svg", // Replace with actual icons
    title: "Multi-Source Connectivity",
    description:
      "Seamlessly connect multiple devices for efficient collaboration.",
    border: "md:border-r-2 border-b-2 border-dashed border-[#BFBFBF]/50",
    dot: "https://content.qeeb.in/coltec/landing/connecting-dot.svg",
  },
  {
    id: 2,
    icon: "https://content.qeeb.in/coltec/landing/wireless-sharing.svg",
    title: "Wireless Sharing",
    description:
      "Share up to four screens simultaneously for dynamic interactions.",
    border: "lg:border-r-2 border-b-2 border-dashed border-[#BFBFBF]/50",
    dot: "https://content.qeeb.in/coltec/landing/connecting-dot.svg",
  },
  {
    id: 3,
    icon: "https://content.qeeb.in/coltec/landing/screen-record-cam-integration.svg",
    title: "Screen Recording & Camera Integration",
    description:
      "Capture every moment with built-in recording and camera functionality.",
    border: "md:border-r-2 border-b-2 border-dashed border-[#BFBFBF]/50",
    dot: "https://content.qeeb.in/coltec/landing/connecting-dot.svg",
  },
  {
    id: 4,
    icon: "https://content.qeeb.in/coltec/landing/live-os-android-updates.svg",
    title: "Live OS Android Updates",
    description: "Stay up to date with the latest features and improvements.",
    border: "border-b-2 border-dashed border-[#BFBFBF]/50",
  },
  {
    id: 5,
    icon: "https://content.qeeb.in/coltec/landing/centralized-remote.svg",
    title: "Centralized Remote Device Management",
    description: "Manage all devices effortlessly from one platform.",
    border: "md:border-r-2 border-b-2 lg:border-b-0 border-dashed border-[#BFBFBF]/50",
  },
  {
    id: 6,
    icon: "https://content.qeeb.in/coltec/landing/android-apps.svg",
    title: "Android Apps",
    description: "Access a wide range of tools via the built-in Android Store.",
    border: "lg:border-r-2 border-dashed border-b-2 lg:border-b-0 border-[#BFBFBF]/50",
  },
  {
    id: 7,
    icon: "https://content.qeeb.in/coltec/landing/child-lock-security.svg",
    title: "Child Lock Security",
    description:
      "Keep your content safe with advanced child-lock capabilities.",
    border: "md:border-r-2 md:border-b-0 border-b-2 border-dashed border-[#BFBFBF]/50",
  },
  {
    id: 8,
    icon: "https://content.qeeb.in/coltec/landing/cloud-ai.svg",
    title: "Cloud & AI Integration",
    description:
      "Effortlessly access, store, and share content with smart AI tools like voice commands, real-time creation, and adaptive learning.",
  },
];
