import riseproductSpecs from "./riseSeries";
import evokeproductSpecs from "./evokeSeries";
import productSpecs from "./inspireSeries";

export const products = {
  "Interactive Flat Panel": [
    {
      id: 1,
      name: "COLTEC Rise | 65 Inch Smart Interactive Flat Panel",
      title:"65 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "1,15,500",
      originalPrice: "1,38,600",
      slug: "65-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-65.webp",
      specs: riseproductSpecs['65"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.webp",
      ],
    },
    {
      id: 2,
      name: "COLTEC Evoke | 65 Inch Smart Interactive Flat Panel",
      title:"65 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "1,03,950",
      originalPrice: "1,24,740",
      slug: "65-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-65.webp",
      specs: evokeproductSpecs['65"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.webp",
      ],
    },
    {
      id: 3,
      name: "COLTEC Inspire | 65 Inch Smart Interactive Flat Panel",
      title:"65 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "1,50,150",
      originalPrice: "1,80,180",
      slug: "65-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-65.webp",
      specs: productSpecs['65"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.webp",
      ],
    },
    {
      id: 4,
      name: "COLTEC Rise | 75 Inch Smart Interactive Flat Panel",
      title:"75 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "1,48,500",
      originalPrice: "1,78,200",
      slug: "75-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-75.webp",
      specs: riseproductSpecs['75"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.webp",
      ],
    },
    {
      id: 5,
      name: "COLTEC Evoke | 75 Inch Smart Interactive Flat Panel",
      title:"75 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "1,33,650",
      originalPrice: "1,60,380",
      slug: "75-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-75.webp",
      specs: evokeproductSpecs['75"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.webp",
      ],
    },
    {
      id: 6,
      name: "COLTEC Inspire | 75 Inch Smart Interactive Flat Panel",
      title:"75 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "1,93,050",
      originalPrice: "2,31,660",
      slug: "75-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-75.webp",
      specs: productSpecs['75"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.webp",
      ],
    },
    {
      id: 7,
      name: "COLTEC Rise | 86 Inch Smart Interactive Flat Panel",
      title:"86 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "1,76,000",
      originalPrice: "2,11,200",
      slug: "86-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-86.webp",
      specs: riseproductSpecs['86"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.webp",
      ],
    },
    {
      id: 8,
      name: "COLTEC Evoke | 86 Inch Smart Interactive Flat Panel",
      title:"86 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "1,58,400",
      originalPrice: "1,90,080",
      slug: "86-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-86.webp",
      specs: evokeproductSpecs['86"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.webp",
      ],
    },
    {
      id: 9,
      name: "COLTEC Inspire | 86 Inch Smart Interactive Flat Panel",
      title:"86 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "2,28,800",
      originalPrice: "2,74,560",
      slug: "86-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-86.webp",
      specs: productSpecs['86"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.webp",
      ],
    },
    {
      id: 10,
      name: "COLTEC Rise | 98 Inch Smart Interactive Flat Panel",
      title:"98 Inch Coltec Rise Powerful Interactive Flat Panel",
      price: "4,40,000",
      originalPrice: "5,28,000",
      slug: "98-inch-coltec-rise-powerful-interactive-flat-panel",
      keywords:"COLTEC Rise, Interactive Flat Panel, Rise Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/rise-98.webp",
      specs: riseproductSpecs['98"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/rise-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/rise-listing-4.webp",
      ],
    },
    {
      id: 11,
      name: "COLTEC Evoke | 98 Inch Smart Interactive Flat Panel",
      title:"98 Inch Coltec Evoke Powerful Interactive Flat Panel",
      price: "3,96,000",
      originalPrice: "4,75,200",
      slug: "98-inch-coltec-evoke-powerful-interactive-flat-panel",
      keywords:"COLTEC Evoke, Interactive Flat Panel, Evoke Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/evoke-98.webp",
      specs: evokeproductSpecs['98"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/evoke-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/evoke-listing-4.webp",
      ],
    },
    {
      id: 12,
      name: "COLTEC Inspire | 98 Inch Smart Interactive Flat Panel",
      title:"98 Inch Coltec Inspire Powerful Interactive Flat Panel",
      price: "5,72,000",
      originalPrice: "6,86,400",
      slug: "98-inch-coltec-inspire-powerful-interactive-flat-panel",
      keywords:"COLTEC Inspire, Interactive Flat Panel, Inspire Interactive Display Panels",
      metaDescription:"Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      description:
        "Experience seamless interaction with our Interactive Display Panels, designed to transform meetings and classrooms into engaging, collaborative spaces.",
      imageUrl: "https://content.qeeb.in/coltec/products/inspire-98.webp",
      specs: productSpecs['98"'],
      gallery: [
        "https://content.qeeb.in/coltec/products/listing/inspire-listing.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-1.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-2.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-3.webp",
        "https://content.qeeb.in/coltec/products/listing/inspire-listing-4.webp",
      ],
    },
  ],
};
