import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../utils/adminContext";
// Import the API utility function
import { validateAdmin } from "../utils/adminData"; // Adjust the path as necessary

const AdminNavbar = () => {
  // State for tracking sign-in status, modal visibility, and form data
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const {
    isLoggedIn,
    isSignInModalOpen,
    openSignInModal,
    closeSignInModal,
    login,
  } = useAuth();


  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle the sign-in submission with API call and toast notification on success
  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = await validateAdmin(formData.userName, formData.password);
      // Check API response: status 1 means success.
      if (data.status === 1) {
        toast.success(data.message || "User validated successfully.");
       login();
      } else {
        toast.error("Invalid credentials");
      }
    } catch (error) {
      console.error("Error during sign in:", error);
      toast.error("Error during sign in. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Toast container for showing notifications */}
      <ToastContainer />

      {/* Navbar */}
      <nav
        className={`flex items-center w-full justify-between py-3 transition-all duration-150 ease-in-out border-b border-sky-200 md:px-8 px-5 ${
          isFixed
            ? "nav-fixed-bar"
            : ""
        } `}
      >
        {/* Left side: Logo */}
        <div className="flex items-center text-white font-bold text-xl">
          <img
            src="https://content.qeeb.in/coltec/coltec-logo-black.svg"
            alt="Admin Logo"
            className="h-7"
          />
        </div>

        {/* Right side: Either Sign In button or default profile pic */}
        <div>
          {isLoggedIn ? (
            <img
              src="https://content.qeeb.in/coltec/default-avatar.png"
              alt="Profile"
              className="rounded-full h-10 w-10"
            />
          ) : (
            <button
              onClick={openSignInModal}
              className="bg-blue-500 hover:bg-blue-600 font-medium text-white px-4 py-2 rounded-lg"
            >
              Sign In
            </button>
          )}
        </div>
      </nav>

      {/* Modal Popup for Sign In */}
      {!isLoggedIn && isSignInModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Background overlay */}
          <div
            className="absolute inset-0 bg-black opacity-50"
            onClick={closeSignInModal}
          ></div>

          {/* Modal content */}
          <div className="relative bg-white rounded-lg p-6 w-96 z-10">
            <h2 className="text-xl font-semibold mb-4">Admin Sign In</h2>
            <form onSubmit={handleSignInSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 mb-1">Username</label>
                <input
                  type="text"
                  name="userName"
                  value={formData.userName}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  placeholder="Enter username"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 mb-1">Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  placeholder="Enter password"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={closeSignInModal}
                  className="mr-4 text-gray-700"
                  disabled={loading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  disabled={loading}
                >
                  {loading ? "Signing In..." : "Sign In"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminNavbar;
