import axios from "axios";
// Get base URL from .env file
const baseUrl = process.env.REACT_APP_API_URL;
const deleteComment = async (commentId) => {
  try {
    // Construct the URL with the dynamic commentId
    const response = await axios.get(
      `${baseUrl}/api/ColtecAdmin/DeleteComment?CommentId=${commentId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting comment:", error);
    throw error;
  }
};

export default deleteComment;
