export const deleteCartProduct = async (productId) => {
  // Get base URL from .env file
  const baseUrl = process.env.REACT_APP_API_URL;
  try {
    const response = await fetch(
      `${baseUrl}/api/Coltec/DeleteCartProduct?Id=${productId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to delete product");
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};
